import { createStore, applyMiddleware, type Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools';
import { defaultState } from '../default-state';
import rootEpic from '../epics';
import type { State } from '../model';
import rootReducer from '../reducers';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (initialState: State = defaultState) => {
	const store: Store<State> = createStore(
		rootReducer,
		initialState,
		composeWithDevtools({ name: 'LocationPicker' })(
			applyMiddleware(createEpicMiddleware(rootEpic)),
		),
	);
	return store;
};

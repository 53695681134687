import React, { type ComponentType } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { FilterViewProps } from '../../../model';

type Props<TFilter> = {
	hasEntities: boolean;
	FilterView: ComponentType<FilterViewProps<TFilter>>;
} & FilterViewProps<TFilter>;

export default function Filter<T>(props: Props<T>) {
	const { hasEntities, FilterView, onFilter } = props;
	return hasEntities ? (
		<Box xcss={wrapperStyles}>
			<FilterView onFilter={onFilter} />
		</Box>
	) : null;
}

const wrapperStyles = xcss({
	marginBottom: 'space.200',
});

import { connect } from '@atlassian/jira-react-redux';
import { storeOptions } from '../common/remapped-redux';
import Content from './content';
import Modals from './modals';
import DirectoryBase from './view';

export default connect(
	() => ({
		Content,
		Modals,
	}),
	null,
	null,
	storeOptions,
	// @ts-expect-error - TS2345 - Type 'any' is not assignable to type 'never'.
)(DirectoryBase);

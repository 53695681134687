import React, { type ReactElement } from 'react';
import { Flex } from '@atlaskit/primitives';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { Filter as FilterType } from '../../../model';

type OnSearchChange = (arg1: string, arg2: AnalyticsEvent) => void;

export type Props = {
	value: FilterType;
	renderFilterSearch: (arg1: OnSearchChange) => ReactElement;
	onChange: (arg1: FilterType, arg2: AnalyticsEvent) => void;
};

const Filter = (props: Props) => {
	const { renderFilterSearch, onChange, value } = props;
	const onSearchChange: OnSearchChange = (text, analyticsEvent) => {
		onChange({ ...value, text }, analyticsEvent);
	};
	return (
		<Flex alignItems="center" gap="space.200">
			{renderFilterSearch(onSearchChange)}
		</Flex>
	);
};

export default Filter;

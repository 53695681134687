import React, { type ComponentType } from 'react';
import EmptyState from '@atlaskit/empty-state';
import type { Props as CreateDialogLoaderProps } from '@atlassian/jira-create-boards-button/src/view/create-dialog-loader';
import type { Props as CreateButtonProps } from '@atlassian/jira-create-boards-button/src/view/stateless-button';
import type { IntlShape } from '@atlassian/jira-intl';
import imageUrl from './assets/board.svg';
import messages from './messages';

type Props = {
	CreateDialogLoader: ComponentType<CreateDialogLoaderProps>;
	CreateButton: ComponentType<CreateButtonProps>;
	intl: IntlShape;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		intl: { formatMessage },
		CreateDialogLoader,
		CreateButton,
	} = props;

	return (
		<CreateDialogLoader>
			{(load, isLoading) => (
				<EmptyState
					imageUrl={imageUrl}
					header={formatMessage(messages.header)}
					description={formatMessage(messages.description)}
					isLoading={isLoading}
					primaryAction={<CreateButton onClick={load} isDisabled={isLoading} withSpinner={false} />}
				/>
			)}
		</CreateDialogLoader>
	);
};

import { createSelector } from 'reselect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const newLocationSelector = (state: any) => state.newLocation;

export const newLocationIdSelector = createSelector(
	newLocationSelector,
	(newLocation) => newLocation.location.id,
);

export const newLocationTypeSelector = createSelector(
	newLocationSelector,
	(newLocation) => newLocation.type,
);

import type { Epic } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import { pageReady } from '../../services/metrics';
import { READY } from '../../state/actions/app';
import { isWithPageReadyMetrics } from '../../state/selectors/app';
import type { State, Action } from '../../state/types';

const pageReadyEpic: Epic<Action, State> = (action$, store) =>
	action$
		.ofType(READY)
		.do(() => {
			isWithPageReadyMetrics(store.getState()) && pageReady();
		})
		.ignoreElements();

export default pageReadyEpic;

import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src';
import FavouriteCellView from '@atlassian/jira-common-directory-v2/src/components/favourite-cell-v2';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../../model';
import {
	getName,
	isFavourite,
	isPending,
	getChangeFavourite,
} from '../../../../../state/selectors/boards';
import type { State } from '../../../../../state/types';

const mapStateToPropsFactory = () => {
	const onClickFactory = memoizeOne(
		(state: State, id: string) => (analyticsEvent: UIAnalyticsEvent) => {
			const changeFavourite = getChangeFavourite(state);
			return changeFavourite(
				{
					id,
					type: 'boards',
					value: !isFavourite(state, id),
				},
				analyticsEvent,
			);
		},
	);
	return (state: State, ownProps: EntityCellProps) => ({
		isFavourite: isFavourite(state, ownProps.id),
		favouriteItemName: getName(state, ownProps.id),
		pending: isPending(state, ownProps.id),
		onClick: onClickFactory(state, ownProps.id),
		isShownInList: true,
	});
};

export default flow(
	AnalyticsSubject('board'),
	// @ts-expect-error - TS2589 - Type instantiation is excessively deep and possibly infinite.
	connect(mapStateToPropsFactory, {}),
)(FavouriteCellView);

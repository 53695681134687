import { type PageRequest, createPageRequest } from '@atlassian/jira-shared-types';
import { ITEMS_PER_PAGE, EXPAND_PARAMETERS } from '../../model/constants';
import { NAME } from '../../model/sort-field';
import { getSortField, getSortDirection, getPage } from '../../state/selectors/query';
import type { State } from '../../state/types';

const SORT_FIELDS = {
	[NAME]: 'NAME',
} as const;

const getSort = (state: State) => ({
	direction: getSortDirection(state),
	field: SORT_FIELDS[getSortField(state)],
});

export const getServiceRequest = (state: State): PageRequest =>
	createPageRequest(getPage(state), ITEMS_PER_PAGE, getSort(state), EXPAND_PARAMETERS);

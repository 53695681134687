import type { Props, Sprint } from '../model';

// SET APP PROPS

export const SET_APP_PROPS = 'state.SET_APP_PROPS' as const;

export type SetAppPropsAction = {
	type: typeof SET_APP_PROPS;
	payload: Props;
};

export const setAppProps = (payload: Props): SetAppPropsAction => ({
	type: SET_APP_PROPS,
	payload,
});

// GET ACTIVE SPRINTS

export const GET_ACTIVE_SPRINTS = 'state.GET_ACTIVE_SPRINTS' as const;

export type GetActiveSprintsAction = {
	type: typeof GET_ACTIVE_SPRINTS;
};

export const getActiveSprints = (): GetActiveSprintsAction => ({
	type: GET_ACTIVE_SPRINTS,
});

// ACTIVE SPRINTS RETRIEVED

export const ACTIVE_SPRINTS_RETRIEVED = 'state.ACTIVE_SPRINTS_RETRIEVED' as const;

type ActiveSprintsRetrievedPayload = Sprint[];

export type ActiveSprintsRetrievedAction = {
	type: typeof ACTIVE_SPRINTS_RETRIEVED;
	payload: ActiveSprintsRetrievedPayload;
};

export const activeSprintsRetrieved = (
	payload: ActiveSprintsRetrievedPayload,
): ActiveSprintsRetrievedAction => ({
	type: ACTIVE_SPRINTS_RETRIEVED,
	payload,
});

// GET ACTIVE SPRINTS FAILED

export const GET_ACTIVE_SPRINTS_FAILED = 'state.GET_ACTIVE_SPRINTS_FAILED' as const;

export type GetActiveSprintsFailedAction = {
	type: typeof GET_ACTIVE_SPRINTS_FAILED;
};

export const getActiveSprintsFailed = (): GetActiveSprintsFailedAction => ({
	type: GET_ACTIVE_SPRINTS_FAILED,
});

// DELETE BOARD

export const DELETE_BOARD = 'state.DELETE_BOARD' as const;

export type DeleteBoardAction = {
	type: typeof DELETE_BOARD;
};

export const deleteBoard = (): DeleteBoardAction => ({
	type: DELETE_BOARD,
});

// DELETE BOARD FAILED

export const DELETE_BOARD_FAILED = 'state.DELETE_BOARD_FAILED' as const;

type DeleteBoardFailedPayload = {
	reason: 'board-not-found' | 'unknown';
};

export type DeleteBoardFailedAction = {
	type: typeof DELETE_BOARD_FAILED;
	payload: DeleteBoardFailedPayload;
};

export const deleteBoardFailed = (payload: DeleteBoardFailedPayload): DeleteBoardFailedAction => ({
	type: DELETE_BOARD_FAILED,
	payload,
});

// BOARD DELETED

export const BOARD_DELETED = 'state.BOARD_DELETED' as const;

export type BoardDeletedAction = {
	type: typeof BOARD_DELETED;
};

export const boardDeleted = (): BoardDeletedAction => ({
	type: BOARD_DELETED,
});

export type Action =
	| SetAppPropsAction
	| GetActiveSprintsAction
	| ActiveSprintsRetrievedAction
	| GetActiveSprintsFailedAction
	| DeleteBoardAction
	| BoardDeletedAction
	| DeleteBoardFailedAction;

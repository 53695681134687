import type { ComponentType } from 'react';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import type { SortDirection } from '@atlassian/jira-common-constants/src/sort-directions';
import { calculateTotalPages } from '@atlassian/jira-common-pagination';
import type {
	DataProvider,
	SpotlightCellTarget,
	EntityOperations,
	FilterViewProps,
	Query,
	HeaderConfiguration,
	OnPatch,
	Props,
	EmptyViewProps,
	ErrorViewProps,
	EmptyFilterResultConfiguration,
	CreateModalProps,
	OperationModalProps,
	ColumnAlignment,
	TableConfigurationNext,
	TableConfiguration,
} from '../../model';
import type { State } from '../index';

export const getAppProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId> => state.app.props;

export const getEntityIds = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): string[] => state.app.props.entityIds;

export const getDataProvider = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): DataProvider<TEntity> | undefined => state.app.props.dataProvider || undefined;

export const getTotalItems = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): number => state.app.props.totalItems;

export const getPage = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): number => state.app.props.page;

export const getItemsPerPage = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): number => state.app.props.itemsPerPage;

export const getSortField = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): TSortField => state.app.props.sortField;

export const getSortDirection = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): SortDirection => state.app.props.sortDirection;

export const getFilter = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): TFilter | undefined => state.app.props.filter;

export const getHeaderConfiguration = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): HeaderConfiguration<TFilter> | undefined => state.app.props.headerConfiguration;

export const getFilterView = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ComponentType<FilterViewProps<TFilter>> | undefined => state.app.props.FilterView;

export const getTableConfiguration = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
):
	| TableConfiguration<TOperation, TSortField, TChildEntityId>
	| TableConfigurationNext<TOperation, TSortField, TChildEntityId, TEntity> =>
	state.app.props.tableConfiguration;

export const getTableLabel = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => state.app.props.tableLabel;

export const getResultsAnnouncerMessage = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => state.app.props.resultsAnnouncerMessage;

export const getEmptyView = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ComponentType<EmptyViewProps> => state.app.props.EmptyView;

export const getErrorView = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ComponentType<ErrorViewProps> => state.app.props.ErrorView;

export const getEmptyFilterResultConfiguration = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): EmptyFilterResultConfiguration => state.app.props.emptyFilterResultConfiguration;

export const getTableMaskView = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ComponentType<Record<any, any>> | undefined => state.app.props.TableMaskView;

export const getCreateModal = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ComponentType<CreateModalProps> | undefined => state.app.props.CreateModal;

export const getOperationModal = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
	operation: TOperation,
): ComponentType<OperationModalProps<TEntity>> => state.app.props.operationModals[operation];

export const getOnReload = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ((arg1: Partial<Query<TSortField, TFilter>>) => void) => state.app.props.onReload;

export const getOnPatch = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): OnPatch<TEntity> => state.app.props.onPatch;

export const isLoading = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): boolean => state.app.props.isLoading;

export const isError = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): boolean => state.app.props.isError;

const getTotalPagesInternal = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	getTotalItems,
	getItemsPerPage,
	(totalItems, itemsOnPage): number => calculateTotalPages(totalItems, itemsOnPage),
);

export const getTotalPages = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
	// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'number'.
): number => getTotalPagesInternal(state);

export const getFirstColumnHeadingAlignment = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ColumnAlignment | undefined => state.app.props.firstColumnHeadingAlignment;

export const getLastColumnHeadingAlignment = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): ColumnAlignment | undefined => state.app.props.lastColumnHeadingAlignment;

// ==============
// === modals ===
// ==============

export const isCreateOpen = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => state.create.isOpen;

export const getOperations = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): EntityOperations<TOperation, TChildEntityId> => state.operations;

const getOperationInitializationStatusPerEntityInternal = createSelector(
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	(state) => state.operations,
	(operations) =>
		mapValues(
			keyBy(operations, ({ id }) => id),
			({ isInitializing }) => isInitializing,
		),
);

export const getOperationInitializationStatusPerEntity = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): {
	[key: string]: boolean;
} => getOperationInitializationStatusPerEntityInternal(state);

// ==================
// === onboarding ===
// ==================

export const getIsOnboardingEnabled = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => state.app.props.isOnboardingEnabled;

export const getOnboardingSpotlightTargets = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => state.app.props.onboardingSpotlightTargets;

export const getRenderOnboardingSpotlight = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => state.app.props.renderOnboardingSpotlight;

export const getSpotlightForCell =
	<TEntity, TOperation extends string, TSortField extends string, TFilter, TChildEntityId>(
		state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
	) =>
	(id: string, colIndex: number): SpotlightCellTarget | undefined =>
		getOnboardingSpotlightTargets(state).find((c) => c.entityId === id && c.colIndex === colIndex);

export const getAvailableSpotlightTargets = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): string[] =>
	// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'string[]'.
	createSelector(
		// @ts-expect-error - TS2769 - No overload matches this call.
		getTableConfiguration,
		getDataProvider,
		getEntityIds,
		getSpotlightForCell,
		(tableConfig, dataProvider, entitiesIds, getSpotlightCellTarget): string[] => {
			const availableSpotlightTargets: Array<string> = [];

			const ids = dataProvider
				? // @ts-expect-error - TS2345 - Argument of type '(entity: TEntity) => string' is not assignable to parameter of type '(value: unknown, index: number, array: unknown[]) => string'.
					dataProvider.getData().map((entity: TEntity) => dataProvider.idSelector(entity))
				: entitiesIds;

			if (ids && tableConfig) {
				ids.forEach((entityId) => {
					for (let i = 0; i < tableConfig.length; i += 1) {
						const cellMatch: SpotlightCellTarget | undefined = getSpotlightCellTarget(entityId, i);
						if (cellMatch && cellMatch.spotlightId) {
							availableSpotlightTargets.push(entityId);
						}
					}
				});
			}

			return availableSpotlightTargets;
		},
	)(state);

// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import { SearchField } from '@atlassian/jira-searchfield';
import type { Props } from './types';

export const FILTER_DEBOUNCE = 1000;

const debouncedSearch = debounce((value, callback) => callback(value), FILTER_DEBOUNCE);

// eslint-disable-next-line jira/react/no-class-components
class Filter extends PureComponent<Props> {
	static defaultProps = {
		value: '',
		placeholder: '',
		describedById: '',
		hasAutoFocus: false,
		isCompact: true,
		increasedWidth: false,
		placeholderAlwaysVisible: false,
	};

	prevQueryValue = this.props.value;

	prevSearchValue = this.props.value;

	handleNewValue = (newValue: string) => {
		debouncedSearch.cancel();

		if (newValue !== this.props.value) {
			this.props.onChange(newValue);
		}
	};

	handleOnChange = (newValue: string) => {
		this.prevQueryValue = newValue;

		newValue === ''
			? this.handleNewValue(newValue)
			: debouncedSearch(newValue, this.handleNewValue);
	};

	getSearchValue(value: string) {
		const searchValue = value === this.prevSearchValue ? this.prevQueryValue : value;

		if (value !== this.prevSearchValue) {
			this.prevSearchValue = value;
			this.prevQueryValue = value;
		}

		return searchValue;
	}

	render() {
		const searchValue = this.getSearchValue(this.props.value);
		const { hasAutoFocus: autoFocus, ariaLabelText, ...props } = this.props;

		return (
			<SearchField
				{...props}
				autoFocus={autoFocus}
				value={searchValue}
				onChange={this.handleOnChange}
				onEnter={this.handleNewValue}
				ariaLabelText={ariaLabelText}
			/>
		);
	}
}

export default Filter;

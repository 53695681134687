import {
	INITIATE,
	ENTITY_CREATED,
	ENTITY_CREATE_FAILED,
	CANCELLED,
	type Action,
} from '../../actions/create';
import type { CreateState } from './types';

const initialState: CreateState = { isOpen: false };

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CreateState = initialState, action: Action): CreateState => {
	switch (action.type) {
		case INITIATE: {
			return { isOpen: true };
		}

		case ENTITY_CREATED:
		case ENTITY_CREATE_FAILED:
		case CANCELLED: {
			return { isOpen: false };
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

import { defineMessages } from '@atlassian/jira-intl';
import type { Board } from '../../model';

const messages = defineMessages({
	title: {
		id: 'directories.boards-v2.common.flags.board-not-found.title',
		defaultMessage: "We can't find {boardName}",
		description:
			'Title for board operations error flag. The error flag is displayed when we are unable to find the board that user has requested to delete / copy / move.',
	},
	titlePrivateBoard: {
		id: 'directories.boards-v2.common.flags.board-not-found.title-private-board',
		defaultMessage: "We can't find this board",
		description:
			'Title for board operations error flag. This title is used for private board only, i.e. we do not have access to the name of the board. Else, we use the title above.',
	},
	description: {
		id: 'directories.boards-v2.common.flags.board-not-found.description',
		defaultMessage: "Someone might've deleted it. Refresh the page and try again.",
		description:
			'Board operations error flag body which explains the reason why the board that user has requested to delete / copy / move cannot be found.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (board: Board) => {
	// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'.
	if (board.isPrivate) {
		return {
			type: 'error',
			title: messages.titlePrivateBoard,
			description: messages.description,
		};
	}
	return {
		type: 'error',
		// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Board'.
		title: [messages.title, { boardName: board.name }],
		description: messages.description,
	};
};

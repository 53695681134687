import * as actions from '../action-types';

type FetchDataRequestAction = {
	type: typeof actions.FETCH_DATA_REQUEST;
};

export const fetchDataRequest = (): FetchDataRequestAction => ({
	type: actions.FETCH_DATA_REQUEST,
});

type FetchDataSuccessAction = {
	type: typeof actions.FETCH_DATA_SUCCESS;
	payload: unknown;
};

export const fetchDataSuccess = (payload: unknown): FetchDataSuccessAction => ({
	type: actions.FETCH_DATA_SUCCESS,
	payload,
});

type FetchDataFailureAction = {
	type: typeof actions.FETCH_DATA_FAILURE;
};

export const fetchDataFailure = (): FetchDataFailureAction => ({
	type: actions.FETCH_DATA_FAILURE,
});

type SetContextAction = {
	type: typeof actions.SET_CONTEXT;
	payload: unknown;
};

export const setContext = (payload: unknown): SetContextAction => ({
	type: actions.SET_CONTEXT,
	payload,
});

type CancelDialogAction = {
	type: typeof actions.CANCEL_DIALOG;
};

export const cancelDialog = (): CancelDialogAction => ({
	type: actions.CANCEL_DIALOG,
});

export type Action =
	| FetchDataRequestAction
	| FetchDataSuccessAction
	| FetchDataFailureAction
	| SetContextAction
	| CancelDialogAction;

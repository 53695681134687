import flow from 'lodash/flow';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import { connect } from '@atlassian/jira-react-redux';
import { deleteBoard } from '../state/actions';
import {
	getBoard,
	isDeleteInProgress,
	getSprintInformation,
	getOnCancel,
} from '../state/selectors';
import type { State } from '../state/types';
import Content from './content';
import DeleteBoardsModal from './view';

const mapStateToProps = (state: State) => ({
	Content,
	board: getBoard(state),
	sprintInformation: getSprintInformation(state),
	deleteInProgress: isDeleteInProgress(state),
	onCancel: getOnCancel(state),
});

export default flow(
	injectIntl,
	// @ts-expect-error - TS2589 - Type instantiation is excessively deep and possibly infinite.
	connect(mapStateToProps, {
		onDelete: deleteBoard,
	}),
)(DeleteBoardsModal);

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import actionAnalyticsMap from './analytics-actions-map';
import triggerAnalytics from './trigger-analytics';

// @ts-expect-error - TS7031 - Binding element 'type' implicitly has an 'any' type. | TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ "move-single-board-dialog/SET_LOCATION_SUCCESS": (state: any) => { eventNameSuffix: string; eventData: { selectedId: any; selectedType: any; rapidViewId: any; }; }; "move-single-board-dialog/SET_LOCATION_FAILURE": (state: any) => { ...; }; "move-single-board-dialog/INIT_DIALOG": (state: any) => { ...; }; "move-sin...'.
const shouldActionTriggerAnalytics = ({ type }) => actionAnalyticsMap[type] !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAnalyticsForAction = (state: any, action: any) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ "move-single-board-dialog/SET_LOCATION_SUCCESS": (state: any) => { eventNameSuffix: string; eventData: { selectedId: any; selectedType: any; rapidViewId: any; }; }; "move-single-board-dialog/SET_LOCATION_FAILURE": (state: any) => { ...; }; "move-single-board-dialog/INIT_DIALOG": (state: any) => { ...; }; "move-sin...'.
	actionAnalyticsMap[action.type](state, action);

// @ts-expect-error - TS7031 - Binding element 'eventNameSuffix' implicitly has an 'any' type. | TS7031 - Binding element 'eventData' implicitly has an 'any' type.
const triggerAnalyticsForAction = ({ eventNameSuffix, eventData }) =>
	triggerAnalytics(eventNameSuffix, eventData);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: any, store: any) =>
	action$
		.filter(shouldActionTriggerAnalytics)
		// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
		.map((action) => getAnalyticsForAction(store.getState(), action))
		.do(triggerAnalyticsForAction)
		.ignoreElements();

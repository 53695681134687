import React from 'react';
import Button from '@atlaskit/button/standard-button';
import {
	type MessageDescriptorV2 as MessageDescriptor,
	injectIntlV2 as injectIntl,
} from '@atlassian/jira-intl';
import type { Intl } from '@atlassian/jira-shared-types';

type Props = {
	canAdd: boolean;
	caption: MessageDescriptor | undefined;
} & Intl & {
		onAdd: () => void;
	};

function Add(props: Props) {
	const {
		intl: { formatMessage },
		canAdd,
		caption,
		onAdd,
	} = props;
	return canAdd ? (
		<Button appearance="primary" onClick={onAdd}>
			{caption && formatMessage(caption)}
		</Button>
	) : null;
}

Add.defaultProps = {
	canAdd: false,
};

export default injectIntl(Add);

import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import QueryParamAdapter from '@atlassian/jira-boards-query-param-adapter/src';
import PageAppInitialisationAnalytics from '@atlassian/jira-common-analytics-page-app-initialisation';
import { FavouriteChangeConsumer } from '@atlassian/jira-favourite-change-provider/src/view';
import { withSuspense } from '@atlassian/jira-platform-resources-utils';
import { withRouter, useResource, type RouteContext } from '@atlassian/jira-router';
import { boardsDirectoryResource } from '@atlassian/jira-router-resources-directories-boards';
import SpaErrorBoundary from '@atlassian/jira-spa-apps-common/src/error-boundary';
import { AsyncNoSoftwareLicense } from '@atlassian/jira-spa-apps-common/src/error-screens/no-software-license/async';
import {
	AuthenticationCheck,
	LicenseCheck,
} from '@atlassian/jira-spa-apps-common/src/interceptors';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import UFOSegment from '@atlassian/jira-ufo-segment';
import Boards from './view';

export type Props = RouteContext;

const useBoardsDirectoryResource = withSuspense(() => useResource(boardsDirectoryResource));

export const BoardsDirectory = ({ location }: Props) => {
	const state = useBoardsDirectoryResource();
	const tenantContext = useTenantContext();

	const renderContents = () => (
		<QueryParamAdapter location={location}>
			{({ query, onQueryChanged }) => (
				<FavouriteChangeConsumer>
					{(favouriteChangeContext) => (
						<UFOSegment name="boards-directory">
							<Boards
								tenantContext={tenantContext}
								prefetchResult={{ ...state }}
								favouriteChangeContext={favouriteChangeContext}
								query={query}
								onQueryChanged={onQueryChanged}
							/>
						</UFOSegment>
					)}
				</FavouriteChangeConsumer>
			)}
		</QueryParamAdapter>
	);

	return (
		<AuthenticationCheck>
			<LicenseCheck type="software">
				{({ result }) => (result ? renderContents() : <AsyncNoSoftwareLicense />)}
			</LicenseCheck>
		</AuthenticationCheck>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouterWrapper: ComponentType<any> = withRouter(BoardsDirectory);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (props: Record<any, any>) => (
	<>
		<MarkProductStart />
		<SpaErrorBoundary id="spa-app-bootstrap.directories.boards">
			<PageAppInitialisationAnalytics
				appNameSuffixForV2Event="directory.boards"
				appNameForV3OperationalEvent="boardsDirectory"
			/>
			<RouterWrapper {...props} />
		</SpaErrorBoundary>
	</>
);

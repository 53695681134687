import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { BOARDS_ERROR_ANALYTICS } from '@atlassian/jira-boards-common/src/common/constant';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error';
import type FetchError from '@atlassian/jira-fetch/src/utils';
import deleteBoard from '../../services/delete-board';
import { type Action, DELETE_BOARD, deleteBoardFailed, boardDeleted } from '../../state/actions';
import {
	getBaseUrl,
	getBoard,
	getOnBoardDeleted,
	getOnBoardDeleteFailed,
} from '../../state/selectors';
import type { State } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(DELETE_BOARD).mergeMap(() => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const board = getBoard(state);

		return deleteBoard(baseUrl, board.id)
			.mergeMap(() => {
				getOnBoardDeleted(state)(board);
				return Observable.of(boardDeleted());
			})
			.catch((error: FetchError) => {
				logSafeErrorWithoutCustomerData(
					error,
					'directories.boards-v2.delete-modal.ops.delete-board',
					BOARDS_ERROR_ANALYTICS,
				);
				const reason = error.statusCode === 404 ? 'board-not-found' : 'unknown';
				getOnBoardDeleteFailed(state)(board, reason);
				return Observable.of(deleteBoardFailed({ reason }));
			});
	});

import React from 'react';
import concat from 'lodash/concat';
import AvatarGroup from '@atlaskit/avatar-group';
import { Box, xcss } from '@atlaskit/primitives';
import type { Group } from '@atlassian/jira-boards-common/src/model';
import AvatarTitleCell from '@atlassian/jira-common-directory-v2/src/components/avatar-title-cell';
import UserCell from '@atlassian/jira-common-directory-v2/src/components/user-cell';
import type { User } from '@atlassian/jira-common-directory-v2/src/model';
import type { EntityCellWrapperType } from '../../../../model';
import groupAvatar from './assets/group-avatar.svg';

export type AdministratorProps = {
	users: User[] | undefined;
	groups: Group[] | undefined;
	baseUrl: string;
};

type Props = EntityCellWrapperType<AdministratorProps>;

export default function Administrators(props: Props) {
	const { baseUrl, users, groups } = props;

	if (users?.length === 0 && groups?.length === 0) {
		return null;
	}
	if (users?.length === 1 && groups?.length === 0) {
		// @ts-expect-error - Type '{ user: User; baseUrl: string; }' is not assignable to type 'IntrinsicAttributes & Props & { onClick?: (() => void | null | undefined) | undefined; }'.
		return <UserCell user={users[0]} baseUrl={baseUrl} />;
	}
	if (users?.length === 0 && groups?.length === 1) {
		return (
			<AvatarTitleCell appearance="circle" displayName={groups[0].name} avatarUrl={groupAvatar} />
		);
	}
	const data = concat(
		users?.map((user: User) => ({ name: user.displayName, src: user.avatarUrl })),
		groups?.map((group: Group) => ({ name: group.name, src: groupAvatar })),
	).filter(Boolean);

	return (
		<Box xcss={avatarGroupContainerStyles}>
			<AvatarGroup appearance="stack" size="small" data={data} />
		</Box>
	);
}

const avatarGroupContainerStyles = xcss({
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
});

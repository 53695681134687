import React from 'react';
import { styled } from '@compiled/react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

export type Props = {
	intl: IntlShape;
};

function Private(props: Props) {
	const {
		intl: { formatMessage },
	} = props;

	const tooltipContent = <TooltipContent>{formatMessage(messages.tooltip)}</TooltipContent>;

	return (
		<Box xcss={containerStyles}>
			<Tooltip content={tooltipContent}>
				<Lozenge isBold>{formatMessage(messages.lozenge)}</Lozenge>
			</Tooltip>
		</Box>
	);
}

export default injectIntl(Private);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContent = styled.div({
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${19 * gridSize}px`,
});

const containerStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

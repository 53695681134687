import type { BoardAdmins } from '@atlassian/jira-boards-common/src/model';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../model';
import { getBaseUrl } from '../../../../state/selectors/app';
import { getAdministrators } from '../../../../state/selectors/boards';
import type { State } from '../../../../state/types';
import Administrators from './view';

type StateProps = {
	baseUrl: string;
} & BoardAdmins;

type OwnProps = EntityCellProps;

// @ts-expect-error - 2345 - Type 'undefined' is not assignable to type 'User[]'
const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	baseUrl: getBaseUrl(state),
	...getAdministrators(state, ownProps.id),
});

export default connect(mapStateToProps, {})(Administrators);

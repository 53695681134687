import type { Filter } from '@atlassian/jira-boards-main/src/model';
import type {
	FilterToParamTransformer,
	ParamToFilterTransformer,
} from '@atlassian/jira-common-directory-v2/src/query-param-adapter/model';

export const AGILITY_PARAM_VALUE = 'agility';

// @ts-expect-error - TS2741 - Property 'text' is missing in type '{}' but required in type 'Filter'.
export const filterToParams: FilterToParamTransformer<Filter> = (filter = {}) => ({
	contains: filter.text || undefined,
});

export const filterFromParams: ParamToFilterTransformer<Filter> = (params) => ({
	text: params.contains || '',
});

import { combineReducers } from 'redux';
import app from './app';
import create from './create';
import operations from './operations';

export default combineReducers({
	app,
	create,
	operations,
});

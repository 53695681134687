import type { Board, PrivateBoard, Location, UserLocation, ProjectLocation } from './index';

export const isPrivateBoardType = (board: Board | undefined): board is PrivateBoard =>
	board !== undefined && 'isPrivate' in board;

export const isPrivateBoard = (board: Board | undefined): board is PrivateBoard =>
	isPrivateBoardType(board) && board.isPrivate;

export const isUserLocationType = (location: Location | undefined): location is UserLocation =>
	location !== undefined && 'userAccountId' in location;

export const isProjectLocationType = (
	location: Location | undefined,
): location is ProjectLocation => location !== undefined && 'projectKey' in location;

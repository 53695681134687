// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import View from './view';

const CreateButton = () => (
	<AppBase id="directories.boards-v2.create-button">
		<AppStyle>
			<View />
		</AppStyle>
	</AppBase>
);

export default CreateButton;

export type CreateButtonProps = JSX.LibraryManagedAttributes<
	typeof CreateButton,
	ComponentProps<typeof CreateButton>
>;

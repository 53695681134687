import React, { Component } from 'react';
import type { Store } from 'redux';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import AppBase from '@atlassian/jira-app-base';
import type { Location } from '../../types/locations';
import { setContext, fetchDataRequest, fetchDataSuccess } from './state/action-creators';
import type { State } from './state/model';
import createStore from './state/store';
import type { Locations } from './types';
import ConnectedLocationPicker from './view';

export interface Props {
	baseUrl: string;
	placeholder: string;
	label: string;
	// If this is undefined then the app will fetch data from server
	locations?: Locations;
	onProjectSelected: (arg1: Location) => void;
	onUserSelected: (arg1: Location) => void;
	recentProjectsLimit: number;
	onItemUnselected?: () => void;
}

// eslint-disable-next-line jira/react/no-class-components
export default class LocationPickerApp extends Component<Props> {
	static defaultProps = {
		placeholder: '',
		label: '',
		onItemUnselected: noop,
	};

	constructor(props: Props) {
		super(props);
		this.store = createStore();

		const { baseUrl, locations, recentProjectsLimit } = this.props;

		this.store.dispatch(
			setContext({
				baseUrl,
				recentProjectsLimit,
			}),
		);

		isEmpty(locations) || (isEmpty(locations.projects) && isEmpty(locations.user))
			? this.store.dispatch(fetchDataRequest())
			: this.store.dispatch(fetchDataSuccess(locations));
	}

	store: Store<State>;

	render() {
		return (
			<AppBase
				store={this.store}
				id="location-picker-app"
				teamName="a4t-pandora"
				packageName="jiraBoardLocationDialogsCommon"
			>
				<ConnectedLocationPicker
					onProjectSelected={this.props.onProjectSelected}
					onUserSelected={this.props.onUserSelected}
					onItemUnselected={this.props.onItemUnselected}
					label={this.props.label}
					placeholder={this.props.placeholder}
				/>
			</AppBase>
		);
	}
}

import type { CopiedBoardSummary } from '@atlassian/jira-boards-common/src/model';
import { fetchJson } from '@atlassian/jira-fetch';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, boardId: string): Promise<CopiedBoardSummary> => {
	const url = `/rest/greenhopper/1.0/rapidview/${boardId}/copy`;
	const options = {
		method: 'PUT',
	};
	return fetchJson(url, options);
};

import { rapidViewIdSelector } from '../context/context-selector';
import { INIT_DIALOG, CANCEL_DIALOG } from '../dialog/dialog-action-type';
import {
	SET_LOCATION_SUCCESS,
	SET_LOCATION_FAILURE,
} from '../new-location/new-location-action-type';
import {
	newLocationIdSelector,
	newLocationTypeSelector,
} from '../new-location/new-location-selector';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
	[SET_LOCATION_SUCCESS]: (state) => ({
		eventNameSuffix: 'move.single.dialog.location.success',
		eventData: {
			selectedId: newLocationIdSelector(state),
			selectedType: newLocationTypeSelector(state),
			rapidViewId: rapidViewIdSelector(state),
		},
	}),
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
	[SET_LOCATION_FAILURE]: (state) => ({
		eventNameSuffix: 'move.single.dialog.location.failure',
		eventData: {
			selectedId: newLocationIdSelector(state),
			selectedType: newLocationTypeSelector(state),
			rapidViewId: rapidViewIdSelector(state),
		},
	}),
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
	[INIT_DIALOG]: (state) => ({
		eventNameSuffix: 'move.single.dialog.init',
		eventData: {
			rapidViewId: rapidViewIdSelector(state),
		},
	}),
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
	[CANCEL_DIALOG]: (state) => ({
		eventNameSuffix: 'move.single.dialog.cancel',
		eventData: {
			rapidViewId: rapidViewIdSelector(state),
		},
	}),
};

import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { SIMPLE } from '@atlassian/jira-common-directory-v2/src/model/constants';
import { COPY, DELETE, MOVE } from '@atlassian/jira-directory-base/src/model/operation-types';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../model';
import { isPrivate, getType, canEdit, getEditBoardUrl } from '../../../../state/selectors/boards';
import type { State } from '../../../../state/types';
import Actions from './view';

const mapStateToPropsFactory = () => {
	const onDeleteFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(DELETE),
	);
	const onCopyFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(COPY),
	);
	const onMoveFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(MOVE),
	);
	return (state: State, ownProps: EntityCellProps) => ({
		isPrivate: isPrivate(state, ownProps.id),
		editBoardUrl: getEditBoardUrl(state, ownProps.id),
		onDelete: onDeleteFactory(ownProps.onOperationRequested),
		onCopy: onCopyFactory(ownProps.onOperationRequested),
		onMove: onMoveFactory(ownProps.onOperationRequested),
		showSpinner: ownProps.isOperationInProgress,
		isSimple: !isPrivate(state, ownProps.id) && getType(state, ownProps.id) === SIMPLE,
		canEdit: canEdit(state, ownProps.id),
	});
};

export default flow(
	withFireUiAnalytics({
		onEdit: 'edit',
		onDelete: 'delete',
		onCopy: 'copy',
		onMove: 'move',
	}),
	connect(mapStateToPropsFactory, {}),
)(Actions);

import React, { Component } from 'react';
import { Text } from '@atlaskit/primitives';
import type { Board, PublicBoard } from '@atlassian/jira-boards-common/src/model';
import {
	type IntlShapeV2 as IntlShape,
	injectIntlV2 as injectIntl,
	FormattedMessage,
} from '@atlassian/jira-intl';
import type { SprintInformation, Sprint } from '../../model';
import messages from './messages';

type Props = {
	board: Board;
	sprintInformation: SprintInformation;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
class Content extends Component<Props> {
	renderContentForPrivateBoard() {
		const {
			intl: { formatMessage },
		} = this.props;
		return <p>{formatMessage(messages.privateBoardMessage)}</p>;
	}

	renderLearnMoreLink(board: PublicBoard) {
		const {
			intl: { formatMessage },
		} = this.props;

		return (
			<>
				<Text as="p">
					{formatMessage(messages.activeSprintsRecommendation, { boardName: board.name })}
				</Text>
				<Text as="p">
					<Text as="em">
						<a href="https://confluence.atlassian.com/jirasoftwarecloud/running-sprints-in-a-scrum-project-764478111.html">
							{formatMessage(messages.link)}
						</a>
					</Text>
				</Text>
			</>
		);
	}

	renderContentForNoSprints(board: PublicBoard) {
		const {
			intl: { formatMessage },
		} = this.props;
		return <p>{formatMessage(messages.noSprintsMessage, { boardName: board.name })}</p>;
	}

	renderContentForSingleSprint(board: PublicBoard, sprint: Sprint) {
		return (
			<>
				<p>
					<FormattedMessage
						{...messages.singleSprintMessage}
						values={{
							sprintName: <a href={sprint.url}>{sprint.name}</a>,
						}}
					/>
				</p>
				{this.renderLearnMoreLink(board)}
			</>
		);
	}

	renderContentForMultipleSprints(board: PublicBoard, sprints: Sprint[]) {
		const {
			intl: { formatMessage },
		} = this.props;
		return (
			<>
				<p>{formatMessage(messages.multipleSprintsMessage)}</p>
				<ul>
					{sprints.map(({ name, url }, index) => (
						<li key={index}>
							<a href={url}>{name}</a>
						</li>
					))}
				</ul>
				{this.renderLearnMoreLink(board)}
			</>
		);
	}

	renderContentForSprintRetrievalError(board: PublicBoard) {
		const {
			intl: { formatMessage },
		} = this.props;

		return (
			<>
				<p>{formatMessage(messages.retrievalFailedMessage, { boardName: board.name })}</p>
				{this.renderLearnMoreLink(board)}
			</>
		);
	}

	render() {
		const { board, sprintInformation } = this.props;
		// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'.
		if (board.isPrivate) {
			return this.renderContentForPrivateBoard();
		}
		// @ts-expect-error - TS2339 - Property 'error' does not exist on type 'SprintInformation'.
		if (sprintInformation.error) {
			// @ts-expect-error - TS2345 - Argument of type 'Board' is not assignable to parameter of type 'PublicBoard'.
			return this.renderContentForSprintRetrievalError(board);
		}
		// @ts-expect-error - TS2339 - Property 'sprints' does not exist on type 'SprintInformation'.
		if (sprintInformation.sprints.length === 1) {
			// @ts-expect-error - TS2345 - Argument of type 'Board' is not assignable to parameter of type 'PublicBoard'. | TS2339 - Property 'sprints' does not exist on type 'SprintInformation'.
			return this.renderContentForSingleSprint(board, sprintInformation.sprints[0]);
		}
		// @ts-expect-error - TS2339 - Property 'sprints' does not exist on type 'SprintInformation'.
		if (sprintInformation.sprints.length > 1) {
			// @ts-expect-error - TS2345 - Argument of type 'Board' is not assignable to parameter of type 'PublicBoard'. | TS2339 - Property 'sprints' does not exist on type 'SprintInformation'.
			return this.renderContentForMultipleSprints(board, sprintInformation.sprints);
		}
		// @ts-expect-error - TS2345 - Argument of type 'Board' is not assignable to parameter of type 'PublicBoard'.
		return this.renderContentForNoSprints(board);
	}
}

export default injectIntl(Content);

import type { State } from './types';

export const getBaseUrl = (state: State) => state.appProps.baseUrl;

export const getBoard = (state: State) => state.appProps.board;

export const getOnInitialised = (state: State) => state.appProps.onInitialised;
export const getOnBoardDeleted = (state: State) => state.appProps.onBoardDeleted;
export const getOnBoardDeleteFailed = (state: State) => state.appProps.onBoardDeleteFailed;
export const getOnCancel = (state: State) => state.appProps.onCancel;

export const isDeleteInProgress = (state: State) => state.isDeleteInProgress;
export const getSprintInformation = (state: State) => state.sprintInformation;

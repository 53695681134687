import type { Board } from '@atlassian/jira-boards-common/src/model';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'directories.boards-v2.main.flags.delete.failed.title',
		defaultMessage: "We couldn't delete {boardName}",
		description: 'Title of the flag that gets shown when a board failed to be deleted.',
	},
	titlePrivateBoard: {
		id: 'directories.boards-v2.main.flags.delete.failed.title-private-board',
		defaultMessage: "We couldn't delete this board",
		description: 'Title of the flag that gets shown when a private board failed to be deleted.',
	},
	description: {
		id: 'directories.boards-v2.main.flags.delete.failed.description',
		defaultMessage: 'Something went wrong. Try again.',
		description: 'Body of the flag that gets shown when a board failed to be deleted.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (board: Board) => {
	// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'.
	if (board.isPrivate) {
		return {
			type: 'error',
			title: messages.titlePrivateBoard,
			description: messages.description,
		};
	}
	return {
		type: 'error',
		// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Board'.
		title: [messages.title, { boardName: board.name }],
		description: messages.description,
	};
};

import { createSelector } from 'reselect';
import type { SortDirection } from '@atlassian/jira-common-constants/src/sort-directions';
import type { Filter, Query } from '../../../model';
import type { SortField } from '../../../model/sort-field';
import type { State } from '../../types';

export const getQuery = (state: State): Partial<Query> => state.query;

export const getSortField = (state: State): SortField => state.query.sortField;
export const getSortDirection = (state: State): SortDirection => state.query.sortDirection;
export const getPage = (state: State): number => state.query.page;

export const getFilter = (state: State): Filter => {
	// in our case, we **know** that there is always a filter
	if (!state.query.filter) {
		throw new Error('Invalid state.');
	}
	return state.query.filter;
};
export const getSearchText = (state: State): string => getFilter(state).text;

export const isFilterApplied = createSelector(getSearchText, (text) => text.length > 0);

import React, { type ComponentType } from 'react';
import type { CreateModalProps } from '../../../model';

type Props = {
	CreateModal: ComponentType<CreateModalProps>;
} & CreateModalProps;

export default function Create(props: Props) {
	const { CreateModal, onCreated, onCreateFailed, onCancel } = props;
	return <CreateModal onCreated={onCreated} onCreateFailed={onCreateFailed} onCancel={onCancel} />;
}

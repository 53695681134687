import React, { Component, type PointerEvent } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { ComponentWithAnalytics } from '@atlassian/jira-analytics-web-react/src';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import { TransitionComponent } from '@atlassian/jira-common-directory-v2/src/components/transition-component';
import { injectIntl } from '@atlassian/jira-intl';
import type { Intl } from '@atlassian/jira-shared-types';
import messages from './messages';

type Props = {
	isPrivate: boolean;
	isSimple: boolean;
	canEdit: boolean;
	defaultOpen: boolean;
} & Intl & {
		showSpinner: boolean;
		editBoardUrl: string;
		onEdit: (arg1: AnalyticsEvent) => void;
		onDelete: (arg1: AnalyticsEvent) => void;
		onCopy: (arg1: AnalyticsEvent) => void;
		onMove: (arg1: AnalyticsEvent) => void;
	};

type State = {
	isOpen: boolean;
};

const DropdownMenuWithAnalytics = ComponentWithAnalytics('dropdownMenu', {
	onOpenChange: 'open',
})(DropdownMenu);

const DropdownItemWithAnalytics = ComponentWithAnalytics('dropdownItem', {
	onClick: 'clicked',
})(DropdownItem);

// eslint-disable-next-line jira/react/no-class-components
class Actions extends Component<Props, State> {
	static defaultProps = {
		isPrivate: false,
		isSimple: false,
		canEdit: true,
		defaultOpen: false,
		showSpinner: false,
		editBoardUrl: '',
		onEdit: noop,
		onDelete: noop,
		onCopy: noop,
		onMove: noop,
	};

	state = {
		isOpen: this.props.defaultOpen,
	};

	// @ts-expect-error - TS7031 - Binding element 'isOpen' implicitly has an 'any' type.
	onOpenChange = ({ isOpen }) => {
		this.setState(() => ({ isOpen }));
	};

	onEdit = (e: PointerEvent, analytics: AnalyticsEvent) => {
		this.props.onEdit(analytics);
		this.delayedClose();
	};

	/**
	 * Close the menu with a minimal delay so the inner handlers are able to react on keypress properly.
	 * In its current implementation if we close the menu right away it won't open the focused `href`.
	 * Ideally we have to get right of this and few more methods when the bug linked below is fixed.
	 * @see https://ecosystem.atlassian.net/browse/AK-4467
	 */
	delayedClose() {
		setTimeout(() => this.setState(() => ({ isOpen: false })));
	}

	renderSettingsAction() {
		const {
			intl: { formatMessage },
			editBoardUrl,
		} = this.props;

		return (
			<TransitionComponent href={editBoardUrl} onClick={this.onEdit}>
				{(props) => (
					/* @ts-expect-error - Type '{ children: string; href: string; onClick: OnClick; }' is not assignable to type 'Omit<{ children: ReactNode; component?: ComponentType<PropsWithChildren<CustomItemComponentProps>> | undefined; ... 14 more ...; key?: Key | ... 1 more ... | undefined; }, keyof WithAnalyticsEventsProps>'. */
					<DropdownItemWithAnalytics {...props}>
						{formatMessage(messages.editAction)}
					</DropdownItemWithAnalytics>
				)}
			</TransitionComponent>
		);
	}

	renderCopyAction() {
		const {
			intl: { formatMessage },
			onCopy,
		} = this.props;

		return (
			/* @ts-expect-error - Type '(arg1: UIAnalyticsEvent) => void' is not assignable to type '(e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any'. */
			<DropdownItemWithAnalytics onClick={onCopy}>
				{formatMessage(messages.copyAction)}
			</DropdownItemWithAnalytics>
		);
	}

	renderMoveAction() {
		const {
			intl: { formatMessage },
			onMove,
		} = this.props;

		return (
			/* @ts-expect-error - Type '(arg1: UIAnalyticsEvent) => void' is not assignable to type '(e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any'. */
			<DropdownItemWithAnalytics onClick={onMove}>
				{formatMessage(messages.moveAction)}
			</DropdownItemWithAnalytics>
		);
	}

	renderDeleteAction() {
		const {
			intl: { formatMessage },
			onDelete,
		} = this.props;

		return (
			/* @ts-expect-error - Type '(arg1: UIAnalyticsEvent) => void' is not assignable to type '(e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any'. */
			<DropdownItemWithAnalytics onClick={onDelete}>
				{formatMessage(messages.deleteAction)}
			</DropdownItemWithAnalytics>
		);
	}

	render() {
		const { isOpen } = this.state;
		const {
			showSpinner,
			intl: { formatMessage },
			isSimple,
			isPrivate,
			canEdit,
		} = this.props;

		if (isSimple) {
			return null;
		}

		const IconBefore = showSpinner ? (
			<Box xcss={SpinnerContainerStyles}>
				<Spinner size="small" />
			</Box>
		) : (
			<MoreIcon label={formatMessage(messages.tooltip)} />
		);

		// legacy logic: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-plugins/jira-agile/greenhopper/src/main/resources/includes/js/rapid/configuration/ManageBoards.soy
		// check boards spec for more informations: https://extranet.atlassian.com/pages/viewpage.action?pageId=3540485825
		// corresponding issue: https://jdog.jira-dev.com/browse/KITKAT-274
		let moveItem = null;
		let deleteItem = null;
		let copyItem = null;
		let settingsItem = null;

		if (isPrivate) {
			if (canEdit) {
				moveItem = this.renderMoveAction();
			}
			deleteItem = this.renderDeleteAction();
		} else {
			if (canEdit) {
				moveItem = this.renderMoveAction();
				deleteItem = this.renderDeleteAction();
			}
			copyItem = this.renderCopyAction();
			settingsItem = this.renderSettingsAction();
		}

		// @ts-expect-error - TS7031 - Binding element 'triggerRef' implicitly has an 'any' type.
		const triggerFunction = ({ triggerRef, ...props }) => (
			<Button {...props} appearance="subtle" iconBefore={IconBefore} ref={triggerRef} />
		);

		return (
			<Flex justifyContent="end" xcss={ContainerStyles}>
				<DropdownMenuWithAnalytics
					placement="bottom-end"
					isOpen={isOpen}
					onOpenChange={this.onOpenChange}
					trigger={triggerFunction}
				>
					<DropdownItemGroup>
						{settingsItem}
						{copyItem}
						{moveItem}
						{deleteItem}
					</DropdownItemGroup>
				</DropdownMenuWithAnalytics>
			</Flex>
		);
	}
}

// @ts-expect-error - TS2769 - No overload matches this call.
export default injectIntl(Actions);

const SpinnerContainerStyles = xcss({
	height: token('space.200'),
	width: token('space.300'),
});

const ContainerStyles = xcss({
	paddingTop: 'space.050',
	paddingRight: 'space.025',
	paddingBottom: 'space.0',
	paddingLeft: 'space.0',
});

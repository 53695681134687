import type { Dispatch } from 'redux';
import type { Board, OperationFailureReason } from '@atlassian/jira-boards-common/src/model';
import DeleteModal from '@atlassian/jira-delete-boards-modal/src';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model';
import { connect } from '@atlassian/jira-react-redux';
import { type Action, deleted, deleteFailed } from '../../state/actions/operations';
import { getBaseUrl } from '../../state/selectors/app';
import { getBoard } from '../../state/selectors/boards';
import type { State } from '../../state/types';

const mapStateToProps = (state: State, ownProps: OperationModalProps<Board>) => ({
	baseUrl: getBaseUrl(state),
	board: getBoard(state, ownProps.entityId),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OperationModalProps<Board>) => ({
	onInitialised: ownProps.onDialogShown,
	onBoardDeleted: (board: Board) => {
		dispatch(deleted(board));
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		ownProps.onUpdated();
	},
	onBoardDeleteFailed: (board: Board, reason: OperationFailureReason) =>
		dispatch(deleteFailed(board, reason)),
	onCancel: ownProps.onCancel,
});

// @ts-expect-error – (2345) - Type 'undefined' is not assignable to type 'Board'
export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);

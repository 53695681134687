import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import noop from 'lodash/noop';
import { SingleBoardFrameApp } from '@atlassian/jira-board-location-dialogs-common/src/apps/single-board-frame';
import ErrorMessage from '@atlassian/jira-board-location-dialogs-common/src/components/error-message';
import type { SelectedLocation as SelectedLocationType } from '@atlassian/jira-board-location-dialogs-common/src/types/locations';
import { defineMessages, injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import { connect } from '@atlassian/jira-react-redux';
import type { Intl } from '@atlassian/jira-shared-types';
import {
	baseUrlSelector,
	rapidViewIdSelector,
	currentLocationDisplayNameSelector,
	currentLocationAvatarSelector,
} from '../context/context-selector';
import { setLocationSuccess } from '../new-location/new-location-action-creator';
import CurrentLocation from './current-location/current-location-view';
import { cancelDialog } from './dialog-action-creator';
import { isOpenSelector } from './dialog-selector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const i18n: any = defineMessages({
	cancel: {
		id: 'move-single-board.label-for-cancel-button',
		defaultMessage: 'Cancel',
		description: '',
	},
	setLocation: {
		id: 'move-single-board.label-for-set-location-button',
		defaultMessage: 'Set location',
		description: '',
	},
	title: {
		id: 'move-single-board.modal-title',
		defaultMessage: 'Move {boardName}',
		description: '',
	},
	location: {
		id: 'move-single-board.label-for-location',
		defaultMessage: 'New location',
		description: '',
	},
	selectLocation: {
		id: 'move-single-board.placeholder-for-location',
		defaultMessage: 'Select location',
		description: '',
	},
	description: {
		id: 'move-single-board.description',

		defaultMessage:
			"Move the board to a different project or your profile. We'll notify the board's admins.",

		description: '',
	},
	defaultBoardName: {
		id: 'move-single-board.default-board-name',
		defaultMessage: 'board',
		description: '',
	},
});

type DispatchProps = {
	onCancel: () => void;
	onConfirm: (arg1: SelectedLocationType) => void;
	onFetchDataError?: () => void;
	onFetchDataSuccess: () => void;
	onSetLocationError?: () => void;
};

type OwnProps = {
	onLocationSet?: (arg1: SelectedLocationType) => void;
	onCancel?: () => void;
};

type StateProps = {
	baseUrl: string;
	rapidViewId: number;
	currentLocationAvatarUrl: string;
	currentLocationName: string;
	isOpen: boolean;
	showErrorMessage?: boolean;
	boardName?: string;
};

type Props = StateProps & DispatchProps & Intl;

type State = {
	error: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
class MoveSingleBoardDialogImpl extends Component<Props, State> {
	static defaultProps: Partial<Props> = {
		showErrorMessage: true,
		onFetchDataSuccess: noop,
	};

	state = {
		error: false,
	};

	handleOnLocationSetError = (): void => {
		this.setState({ error: true });
		this.props.onSetLocationError && this.props.onSetLocationError();
	};

	handleFetchDataError = (): void => this.props.onFetchDataError && this.props.onFetchDataError();

	clearError = () => this.setState({ error: false });

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderErrorMessage = (): any =>
		this.state.error && this.props.showErrorMessage ? <ErrorMessage /> : null;

	render() {
		const {
			baseUrl,
			rapidViewId,
			onCancel,
			onConfirm,
			intl,
			currentLocationName,
			currentLocationAvatarUrl,
			onFetchDataSuccess,
		} = this.props;

		if (!intl) {
			return null;
		}

		const boardName = this.props.boardName
			? this.props.boardName
			: intl.formatMessage(i18n.defaultBoardName);

		return (
			<SingleBoardFrameApp
				isOpen={this.props.isOpen}
				baseUrl={baseUrl}
				rapidViewId={rapidViewId}
				onLocationSet={onConfirm}
				onLocationSetError={this.handleOnLocationSetError}
				onFetchDataError={this.handleFetchDataError}
				onFetchDataSuccess={onFetchDataSuccess}
				onProjectSelected={this.clearError}
				onUserSelected={this.clearError}
				errorElement={this.renderErrorMessage()}
				onCancel={onCancel}
				confirmActionText={intl.formatMessage(i18n.setLocation)}
				cancelActionText={intl.formatMessage(i18n.cancel)}
				locationPickerLabel={intl.formatMessage(i18n.location)}
				locationPickerPlaceholder={intl.formatMessage(i18n.selectLocation)}
				title={intl.formatMessage(i18n.title, { boardName })}
			>
				<div>
					{intl.formatMessage(i18n.description)}
					{currentLocationName && (
						<CurrentLocation
							locationName={currentLocationName}
							avatarUrl={currentLocationAvatarUrl}
						/>
					)}
				</div>
			</SingleBoardFrameApp>
		);
	}
}

export const MoveSingleBoardDialog = injectIntl(MoveSingleBoardDialogImpl);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
	baseUrl: baseUrlSelector(state),
	rapidViewId: rapidViewIdSelector(state),
	isOpen: isOpenSelector(state),
	currentLocationName: currentLocationDisplayNameSelector(state),
	currentLocationAvatarUrl: currentLocationAvatarSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
	onCancel: () => {
		dispatch(cancelDialog());
		ownProps.onCancel && ownProps.onCancel();
	},
	onConfirm: (location: SelectedLocationType) => {
		// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
		dispatch(setLocationSuccess(location));
		ownProps.onLocationSet && ownProps.onLocationSet(location);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveSingleBoardDialog);

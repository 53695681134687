import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	classicLocationlessActionPromptText: {
		id: 'board-location-dialogs.common.location-picker.update-url-info',
		defaultMessage:
			' This update will redirect you to a new URL for this board. Update any existing bookmarks, resources, or references to make sure they reflect this change.',
		description: 'Prompt user to update the links to their boards as the url has changed',
	},
});

import memoizeOne from 'memoize-one';
import { connect } from '../../../../common/remapped-redux';
import type { HeaderViewProps } from '../../../../model';
import type { State } from '../../../../state';
import { initiate as initiateCreate } from '../../../../state/actions/create';
import { getHeaderConfiguration, getOnReload } from '../../../../state/selectors';
import { getStatus } from '../../common/selectors';
import Wrapper from './view';

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(): ((state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
	HeaderView: Flow.AbstractComponent<HeaderViewProps<TFilter>, unknown>;
	hasEntities: boolean;
	onFilter: (filter: never) => void;
}) => {
	const onFilterFactory = memoizeOne(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => (filter: never) =>
			getOnReload(state)({ page: 1, filter }),
	);
	return (state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
		const headerConfiguration = getHeaderConfiguration(state);
		// @ts-expect-error - TS2339 - Property 'View' does not exist on type 'HeaderConfiguration<TFilter>'.
		if (!headerConfiguration || !headerConfiguration.View) {
			throw new Error('Invalid state - Header wrapper must not be mounted');
		}

		return {
			// @ts-expect-error - TS2339 - Property 'View' does not exist on type 'HeaderConfiguration<TFilter>'.
			HeaderView: headerConfiguration.View,
			hasEntities: getStatus(state) === 'has-entities',
			onFilter: onFilterFactory(state),
		};
	};
};

const mapDispatchToProps = {
	onAdd: initiateCreate,
} as const;

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2. | TS2345 - Argument of type 'typeof Wrapper' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
export default connect(mapStateToPropsFactory, mapDispatchToProps)(Wrapper);

import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools';
import { defaultState } from '../default-state';
import rootEpic from '../epics';
import rootReducer from '../reducers';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (initialState = defaultState) =>
	createStore(
		rootReducer,
		initialState,
		composeWithDevtools({ name: 'SingleBoardFrame' })(
			applyMiddleware(createEpicMiddleware(rootEpic)),
		),
	);

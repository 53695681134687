import React, { Component } from 'react';
import noop from 'lodash/noop';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { PAGE_EVENT_ACTION } from '../utils/events';

type Props = {
	onMount: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
class ViewTracker extends Component<Props> {
	componentDidMount() {
		this.props.onMount();
	}

	render() {
		return null;
	}
}

const ViewTrackerWithAnalytics = withAnalyticsContext({
	componentName: 'view-tracker',
})(
	withAnalyticsEvents({
		onMount: (createEvent) => createEvent({ action: PAGE_EVENT_ACTION }).fire(),
		// @ts-expect-error - TS2345 - Argument of type 'typeof ViewTracker' is not assignable to parameter of type '(((props: WithAnalyticsEventsProps) => ReactElement<any, string | ((props: any) => ReactElement<any, any> | null) | (new (props: any) => Component<any, any, any>)> | null) & typeof ViewTracker) | ((new (props: WithAnalyticsEventsProps) => Component<...>) & typeof ViewTracker)'.
	})(ViewTracker),
);

// without onMount={noop} it won't work, defaultProps won't help
// @ts-expect-error - TS2322 - Type '{ onMount: (...args: any[]) => void; }' is not assignable to type 'IntrinsicAttributes & Pick<Pick<WithAnalyticsEventsProps, never> & RefAttributes<any> & WithContextProps, "key" | "analyticsContext"> & RefAttributes<...>'.
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <ViewTrackerWithAnalytics onMount={noop} />;

import type { PublicBoard, CopiedBoardSummary } from '@atlassian/jira-boards-common/src/model';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'directories.boards-v2.main.flags.copy.success.title',
		defaultMessage: '{boardName} copied',
		description: 'Title of the flag that gets shown when a board is successfully copied.',
	},
	description: {
		id: 'directories.boards-v2.main.flags.copy.success.description',
		defaultMessage:
			'{copiedBoardName} has been created with the same settings and filter as {boardName}',
		description: 'Body of the flag that gets shown when a board is successfully copied.',
	},
	viewBoard: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'directories.boards-v2.main.flags.copy.success.view-board',
		defaultMessage: 'View board',
		description:
			'Action label that is displayed at the bottom of the flag. When clicked, it will take the user to the copied board.',
	},
	editBoard: {
		id: 'directories.boards-v2.main.flags.copy.success.edit-board',
		defaultMessage: 'Edit settings',
		description:
			'Action label that is displayed at the bottom of the flag. When clicked, it will take the user to the settings page for the copied board.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	board: PublicBoard,
	copiedBoardSummary: CopiedBoardSummary,
	copiedBoardUrl: string,
	copiedBoardSettingsUrl: string,
) => ({
	type: 'success',
	title: [messages.title, { boardName: board.name }],
	description: [
		messages.description,
		{ boardName: board.name, copiedBoardName: copiedBoardSummary.name },
	],
	actions: [
		{
			content: messages.viewBoard,
			href: copiedBoardUrl,
		},
		{
			content: messages.editBoard,
			href: copiedBoardSettingsUrl,
		},
	],
});

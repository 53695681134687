import React, { Component } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import AppBase from '@atlassian/jira-app-base';
import type {
	Location,
	SelectedLocation as SelectedLocationType,
} from '@atlassian/jira-board-location-dialogs-common/src/types/locations';
import { setContext } from './context/context-action-creator';
import Dialog from './dialog/dialog-view';
import createStore from './store';

interface DefaultProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLocationSet: (arg1?: any) => void;
	onCancel: () => void;
}

type Props = DefaultProps & {
	baseUrl: string;
	rapidViewId: number;
	currentLocation: Location;
	onLocationSet?: (arg1: SelectedLocationType) => void;
	onCancel?: () => void;
	onSetLocationError?: () => void;
	onFetchDataError?: () => void;
	onFetchDataSuccess: () => void;
	showErrorMessage?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store?: Store<any>;
	boardName?: string;
};

// eslint-disable-next-line jira/react/no-class-components
export default class MoveSingleBoardDialogApp extends Component<Props> {
	static defaultProps = {
		onLocationSet: noop,
		onCancel: noop,
		onSetLocationError: noop,
		onFetchDataError: noop,
		onFetchDataSuccess: noop,
		showErrorMessage: true,
		boardName: '',
	};

	constructor(props: Props) {
		super(props);
		const { baseUrl, rapidViewId, currentLocation, store } = this.props;

		// creates a separate store for each dialog instance
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		this.store = store || createStore();

		this.store.dispatch(
			setContext({
				baseUrl,
				rapidViewId,
				currentLocation,
			}),
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: Store<any>;

	render() {
		const {
			onLocationSet,
			onCancel,
			onSetLocationError,
			onFetchDataError,
			onFetchDataSuccess,
			showErrorMessage,
			boardName,
		} = this.props;

		return (
			<AppBase
				store={this.store}
				id="move-single-board-dialog-app"
				teamName="a4t-pandora"
				packageName="jiraBoardLocationDialogsMoveSingle"
			>
				<Dialog
					onLocationSet={onLocationSet}
					onCancel={onCancel}
					onSetLocationError={onSetLocationError}
					onFetchDataError={onFetchDataError}
					onFetchDataSuccess={onFetchDataSuccess}
					// @ts-expect-error - Property 'showErrorMessage' does not exist on props
					showErrorMessage={showErrorMessage}
					boardName={boardName}
				/>
			</AppBase>
		);
	}
}

import { NAME, type SortField } from '@atlassian/jira-boards-main/src/model/sort-field';
import type {
	ParamToSortFieldTransformer,
	SortFieldToParamTransformer,
} from '@atlassian/jira-common-directory-v2/src/query-param-adapter-legacy/model';

const sortFieldMap: {
	[key: string]: SortField;
} = {
	[NAME.toLowerCase()]: NAME,
};

export const sortFieldToParam: SortFieldToParamTransformer<SortField> = (field) =>
	field.toLowerCase();

export const sortFieldFromParam: ParamToSortFieldTransformer<SortField> = (param) =>
	sortFieldMap[param] ? sortFieldMap[param] : null;

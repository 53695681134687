import { createSelector } from 'reselect';
import type { Location } from '../../../../types/locations';
import type { State, LocationsState, ContextState } from '../model';

const contextSelector = (state: State): ContextState => state.context;
const locationsSelector = (state: State): LocationsState => state.locations;

export const userSelector = createSelector(
	locationsSelector,
	(locations: LocationsState) => locations.user,
);

export const projectsSelector = createSelector(
	locationsSelector,
	(locations: LocationsState) => locations.projects,
);

export const isPendingSelector = createSelector(
	projectsSelector,
	(projects: Location[]) => !projects || projects.length <= 0,
);

/** @deprecated baseUrl in cloud is an empty string. remove code that checks or uses it. */
export const baseUrlSelector = (_: unknown): string => '';

export const recentProjectsLimitSelector = createSelector(
	contextSelector,
	(context: ContextState) => context.recentProjectsLimit,
);

import React, { Component } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import log from '@atlassian/jira-common-util-logging/src/log';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller';
import { TreeReporter } from './common/page-ready';
import { Provider } from './common/remapped-redux';
import type { Props } from './model';
import rootEpic from './ops';
import createStore, { type State, type Action } from './state';
import { setAppProps } from './state/actions/app';
import View from './view';

const defaultEntityIds: Array<never> = [];

export { GenericNoResults } from './assets';

// eslint-disable-next-line jira/react/no-class-components
export default class Directory<
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
> extends Component<Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId>> {
	static defaultProps = {
		isOnboardingEnabled: false,
		headerConfiguration: undefined,
		FilterView: undefined,
		TableMaskView: undefined,
		CreateModal: undefined,
		metrics: {
			tableMounted: undefined,
		},
		entityIds: defaultEntityIds,
		dataProvider: undefined,
		renderOnboardingSpotlight: () => null,
		tableLabel: undefined,
		onboardingSpotlightTargets: [],
		onFirstRender: noop,
	};

	constructor(props: Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) {
		super(props);

		if (props.entityIds !== defaultEntityIds && props.dataProvider) {
			log.safeErrorWithoutCustomerData(
				'directory.base',
				'Please provide only one of props: [entityIds] or [dataProvider], dataProvider will be used',
			);
		}

		this.store = createStore<
			State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
			Action<TEntity, TOperation, TSortField, TFilter, TChildEntityId>
		>(rootEpic);
		this.store.dispatch(setAppProps(props));
	}

	componentDidUpdate() {
		this.store.dispatch(setAppProps(this.props));
	}

	store: Store<State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>>;

	render() {
		return (
			<Provider store={this.store}>
				<TreeReporter onFirstMount={this.props.onFirstRender}>
					<View />
					<SpaStatePageReady />
				</TreeReporter>
			</Provider>
		);
	}
}

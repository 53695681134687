import React, { type ReactNode, Component, type ReactElement } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import AppBase from '@atlassian/jira-app-base';
import type { SelectedLocation as SelectedLocationType } from '../../types/locations';
import { setContext, fetchDataRequest } from './state/action-creators';
import createStore from './state/store';
import ConnectedSingleBoardFrame from './view';

interface Props {
	rapidViewId: number;
	baseUrl: string;
	title: string;
	children: ReactNode;
	onCancel?: () => void;
	isOpen?: boolean;
	onLocationSet?: (arg1: SelectedLocationType) => void;
	onLocationSetError?: (arg1: SelectedLocationType) => void;
	onFetchDataError?: (arg1: SelectedLocationType) => void;
	onFetchDataSuccess: () => void;
	confirmActionText: string;
	cancelActionText: string;
	locationPickerLabel: string;
	locationPickerPlaceholder: string;
	errorElement?: ReactElement | null;
	onUserSelected?: (arg1: SelectedLocationType) => void;
	onProjectSelected?: (arg1: SelectedLocationType) => void;
	classicLocationless?: boolean;
	boardId: string | number;
}

// eslint-disable-next-line jira/react/no-class-components
export default class SingleBoardFrameApp extends Component<Props> {
	static defaultProps = {
		isOpen: false,
		onCancel: noop,
		onLocationSet: noop,
		onLocationSetError: noop,
		onFetchDataError: noop,
		onFetchDataSuccess: noop,
		onUserSelected: noop,
		onProjectSelected: noop,
		errorElement: null,
		classicLocationless: false,
		boardId: null,
	};

	constructor(props: Props) {
		super(props);
		this.store = createStore();

		const { baseUrl, rapidViewId } = this.props;

		this.store.dispatch(
			setContext({
				baseUrl,
				rapidViewId,
			}),
		);
		this.store.dispatch(fetchDataRequest());
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: Store<any>;

	render() {
		return (
			<AppBase
				store={this.store}
				id="single-board-frame-app"
				teamName="a4t-pandora"
				packageName="jiraBoardLocationDialogsCommon"
			>
				<ConnectedSingleBoardFrame
					onCancel={this.props.onCancel}
					isOpen={this.props.isOpen}
					title={this.props.title}
					onLocationSet={this.props.onLocationSet}
					onLocationSetError={this.props.onLocationSetError}
					onFetchDataError={this.props.onFetchDataError}
					onFetchDataSuccess={this.props.onFetchDataSuccess}
					onUserSelected={this.props.onUserSelected}
					onProjectSelected={this.props.onProjectSelected}
					confirmActionText={this.props.confirmActionText}
					cancelActionText={this.props.cancelActionText}
					locationPickerLabel={this.props.locationPickerLabel}
					locationPickerPlaceholder={this.props.locationPickerPlaceholder}
					errorElement={this.props.errorElement}
					classicLocationless={this.props.classicLocationless}
					boardId={this.props.boardId}
				>
					{this.props.children}
				</ConnectedSingleBoardFrame>
			</AppBase>
		);
	}
}

import React from 'react';
import type { Location as LocationType } from '@atlassian/jira-boards-common/src/model';
import AvatarTitleCell from '@atlassian/jira-common-directory-v2/src/components/avatar-title-cell';

type Props = {
	location: LocationType | undefined;
};

export default function Location(props: Props) {
	const { location } = props;
	if (location) {
		const appearance = location.type === 'user' ? 'circle' : 'square';
		return (
			<AvatarTitleCell
				displayName={location.displayName}
				avatarUrl={location.avatarURI}
				appearance={appearance}
			/>
		);
	}
	return null;
}

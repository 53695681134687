import type { ReactElement } from 'react';

type Props = {
	isPrivate: boolean;
	children: (arg1: { isPrivate: boolean }) => ReactElement;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const { isPrivate, children } = props;
	return children({ isPrivate });
};

import React from 'react';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

const StarTitleCell = () => {
	const { formatMessage } = useIntl();
	return <StarFilledIcon size="small" label={formatMessage(messages.starred)} />;
};

export default StarTitleCell;

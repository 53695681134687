import type { Board } from '@atlassian/jira-boards-common/src/model';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'directories.boards-v2.main.flags.move.success.title',
		defaultMessage: '{boardName} moved',
		description: 'Title of the flag that gets shown when a board is successfully moved.',
	},
	titlePrivateBoard: {
		id: 'directories.boards-v2.main.flags.move.success.title-private-board',
		defaultMessage: 'Board moved',
		description: 'Title of the flag that gets shown when a private board is successfully moved.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (board: Board) => {
	// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'.
	if (board.isPrivate) {
		return { type: 'success', title: messages.titlePrivateBoard };
	}
	// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Board'.
	return { type: 'success', title: [messages.title, { boardName: board.name }] };
};

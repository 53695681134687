import flow from 'lodash/flow';
import mapValues from 'lodash/mapValues';
import type { QueryFromUrlTransformer } from '../model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FromUrlTransformer<TQuery> = (arg1: { [key: string]: any }) => Partial<TQuery>;

const fromUrlTransformerFactory = <TQuery,>(
	transformer: QueryFromUrlTransformer<TQuery>,
): FromUrlTransformer<TQuery> =>
	flow([
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(params: any) => mapValues(params, (p) => (Array.isArray(p) ? p[0] : p)),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(params: any) => transformer(params),
	]);

export default fromUrlTransformerFactory;

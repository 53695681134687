import { transformUser } from '@atlassian/jira-common-directory-v2/src/services/transformers';
import type { Board, PrivateBoard, PublicBoard, Location, BoardAdmins } from '../model';
import type {
	Board as RestBoard,
	PrivateBoard as RestPrivateBoard,
	PublicBoard as RestPublicBoard,
	BoardAdmins as RestBoardAdmins,
	Location as RestLocation,
} from '../rest/types';

const transformAdmins = (baseUrl: string, item: RestBoardAdmins): BoardAdmins => ({
	users: item.users.map((user) => transformUser(baseUrl, user)),
	groups: item.groups.map(({ name }) => ({
		name,
	})),
});

export const transformLocation = (item: RestLocation): Location =>
	// @ts-expect-error - TS2339 - Property 'projectKey' does not exist on type 'Location'.
	item.projectKey
		? {
				displayName: item.displayName,
				avatarURI: item.avatarURI,
				// @ts-expect-error - TS2339 - Property 'projectKey' does not exist on type 'Location'.
				projectKey: item.projectKey,
				type: 'project',
			}
		: {
				displayName: item.displayName,
				avatarURI: item.avatarURI,
				// @ts-expect-error - TS2339 - Property 'userAccountId' does not exist on type 'Location'. | TS2339 - Property 'userAccountId' does not exist on type 'Location'.
				userAccountId: item.userAccountId != null ? item.userAccountId : '',
				type: 'user',
			};

const transformPrivateBoard = (baseUrl: string, item: RestPrivateBoard): PrivateBoard => ({
	id: `${item.id}`,
	isPrivate: true,
	admins: transformAdmins(baseUrl, item.admins),
	canEdit: item.canEdit,
	location: item.location && transformLocation(item.location),
});

const transformPublicBoard = (baseUrl: string, item: RestPublicBoard): PublicBoard => ({
	id: `${item.id}`,
	name: item.name,
	type: item.type,
	favourite: item.favourite,
	admins: transformAdmins(baseUrl, item.admins),
	canEdit: item.canEdit,
	location: item.location && transformLocation(item.location),
});

export const transformBoard = (baseUrl: string, item: RestBoard): Board =>
	// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'. | TS2345 - Argument of type 'Board' is not assignable to parameter of type 'PrivateBoard'. | TS2345 - Argument of type 'Board' is not assignable to parameter of type 'PublicBoard'.
	item.isPrivate ? transformPrivateBoard(baseUrl, item) : transformPublicBoard(baseUrl, item);

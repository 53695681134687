import type { Props } from '../../../model';

// SET APP PROPS

export const SET_APP_PROPS = 'state.actions.app.SET_APP_PROPS' as const;

export type SetAppPropsAction<
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
> = {
	type: typeof SET_APP_PROPS;
	payload: Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId>;
};

export const setAppProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	payload: Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): SetAppPropsAction<TEntity, TOperation, TSortField, TFilter, TChildEntityId> => ({
	type: SET_APP_PROPS,
	payload,
});

export type Action<TEntity, TOperation, TSortField, TFilter, TChildEntityId> = SetAppPropsAction<
	TEntity,
	// @ts-expect-error - TS2344 - Type 'TOperation' does not satisfy the constraint 'string'.
	TOperation,
	TSortField,
	TFilter,
	TChildEntityId
>;

import { combineReducers } from 'redux';
import { freeze } from 'icepick';
import contextReducer from './context/context-reducer';
import dialogReducer from './dialog/dialog-reducer';
import newLocationReducer from './new-location/new-location-reducer';

const initialState = freeze({
	context: undefined,
	dialog: undefined,
	newLocation: undefined,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state = initialState, action: any) =>
	freeze(
		combineReducers({
			context: contextReducer,
			newLocation: newLocationReducer,
			dialog: dialogReducer,
		})(
			// @ts-expect-error - Argument of type '{ context: undefined; dialog: undefined; newLocation: undefined; }' is not assignable to parameter of type 'CombinedState<{ context: any; newLocation: any; dialog: { isOpen: boolean; }; }>'.
			state,
			action,
		),
	);

import { stringify } from 'query-string';
import { fetchJson } from '@atlassian/jira-fetch';
import type { Page } from '@atlassian/jira-shared-types';
import type { Sprint } from '../../model';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, boardId: string): Promise<Page<Sprint>> => {
	const params = stringify({
		state: 'active',
	});

	return fetchJson(`/rest/agile/1.0/board/${boardId}/sprint?${params}`);
};

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { BOARDS_ERROR_ANALYTICS } from '@atlassian/jira-boards-common/src/common/constant';
import { SCRUM } from '@atlassian/jira-common-directory-v2/src/model/constants';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error';
import type FetchError from '@atlassian/jira-fetch/src/utils';
import getActiveSprints from '../../services/get-active-sprints';
import {
	type Action,
	GET_ACTIVE_SPRINTS,
	activeSprintsRetrieved,
	getActiveSprintsFailed,
} from '../../state/actions';
import { getBaseUrl, getBoard, getOnInitialised } from '../../state/selectors';
import type { State } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(GET_ACTIVE_SPRINTS).mergeMap(() => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const board = getBoard(state);

		// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'. | TS2339 - Property 'type' does not exist on type 'Board'.
		const shouldSkipCheckingActiveSprints = board.isPrivate || board.type !== SCRUM;
		if (shouldSkipCheckingActiveSprints) {
			getOnInitialised(state)();
			return Observable.of(activeSprintsRetrieved([]));
		}

		return (
			Observable.fromPromise(getActiveSprints(baseUrl, board.id))
				.mergeMap((sprints) => {
					getOnInitialised(state)();
					return Observable.of(activeSprintsRetrieved(sprints));
				})
				// @ts-expect-error - TS2345 - Argument of type '(error: FetchError) => Observable<ActiveSprintsRetrievedAction> | Observable<GetActiveSprintsFailedAction>' is not assignable to parameter of type '(err: any, caught: Observable<ActiveSprintsRetrievedAction>) => ObservableInput<ActiveSprintsRetrievedAction>'.
				.catch((error: FetchError) => {
					if (error.statusCode === 404) {
						return Observable.of(activeSprintsRetrieved([]));
					}
					logSafeErrorWithoutCustomerData(
						error,
						'directories.boards-v2.delete-modal.ops.get-active-sprints',
						BOARDS_ERROR_ANALYTICS,
					);
					return Observable.of(getActiveSprintsFailed());
				})
		);
	});

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import getLocations$ from '../../../../../requests/get-locations';
import { type Action, fetchDataSuccess, fetchDataFailure } from '../../action-creators';
import { FETCH_DATA_REQUEST } from '../../action-types';
import type { State } from '../../model';
import { baseUrlSelector, recentProjectsLimitSelector } from '../../selectors';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
): ActionsObservable<Action> =>
	// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<FetchDataSuccessAction | FetchDataFailureAction>' but required in type 'ActionsObservable<Action>'.
	action$
		.ofType(FETCH_DATA_REQUEST)
		.switchMap(() => {
			const state = store.getState();
			return getLocations$(baseUrlSelector(state), recentProjectsLimitSelector(state));
		})
		.map(fetchDataSuccess)
		.catch(() => Observable.of(fetchDataFailure()));

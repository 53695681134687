import memoizeOne from 'memoize-one';
import { createSelector } from 'react-sweet-state';
import { connect } from '@atlassian/jira-react-redux';
import { withMountReporting } from '../../common/page-ready';
import { storeOptions } from '../../common/remapped-redux';
import type { State } from '../../state';
import { initiate as initiateCreate } from '../../state/actions/create';
import {
	getHeaderConfiguration,
	getFilterView,
	getEmptyView,
	getErrorView,
	getOnReload,
	getIsOnboardingEnabled,
	getRenderOnboardingSpotlight,
	getAvailableSpotlightTargets,
} from '../../state/selectors';
import { getStatus } from './common/selectors';
import Filter from './filter';
import DefaultHeader from './header/default';
import HeaderWrapper from './header/wrapper';
import Paginator from './paginator';
import Table from './table';
import Content from './view';

const getEmptyViewSelectorMemoized = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() =>
	createSelector(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) =>
			withMountReporting(getEmptyView(state)),
		(res) => res,
	);

const getErrorViewSelectorMemoized = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() =>
	createSelector(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) =>
			withMountReporting(getErrorView(state)),
		(res) => res,
	);

const getAvailableSpotlightTargetsSelectorMemoized = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() =>
	createSelector(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) =>
			getAvailableSpotlightTargets(state),
		(res) => res,
	);

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() => {
	const getEmptyViewSelectorInstance = getEmptyViewSelectorMemoized<
		TEntity,
		TOperation,
		TSortField,
		TFilter,
		TChildEntityId
	>();
	const getErrorViewSelectorInstance = getErrorViewSelectorMemoized<
		TEntity,
		TOperation,
		TSortField,
		TFilter,
		TChildEntityId
	>();
	const getAvailableSpotlightTargetsSelectorInstance = getAvailableSpotlightTargetsSelectorMemoized<
		TEntity,
		TOperation,
		TSortField,
		TFilter,
		TChildEntityId
	>();
	const onRetryFactory = memoizeOne(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => () =>
			getOnReload(state)({}),
	);

	return (state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
		let Header;

		const FilterView = getFilterView(state);
		if (FilterView) {
			Header = Filter;
		} else {
			const headerConfiguration = getHeaderConfiguration(state);
			if (headerConfiguration) {
				// @ts-expect-error - TS2339 - Property 'View' does not exist on type 'HeaderConfiguration<TFilter>'.
				if (headerConfiguration.View) {
					Header = HeaderWrapper;
				} else {
					Header = DefaultHeader;
				}
			}
		}

		return {
			Header,
			Table,
			Paginator,
			Empty: getEmptyViewSelectorInstance(state),
			Error: getErrorViewSelectorInstance(state),
			status: getStatus(state),
			onRetry: onRetryFactory(state),
			isOnboardingEnabled: getIsOnboardingEnabled(state),
			renderOnboardingSpotlight: getRenderOnboardingSpotlight(state),
			availableSpotlightTargets: getAvailableSpotlightTargetsSelectorInstance(state),
		};
	};
};

const mapDispatchToProps = {
	onAdd: initiateCreate,
} as const;

export default connect(
	mapStateToPropsFactory,
	mapDispatchToProps,
	null,
	storeOptions,
	// @ts-expect-error - TS2345 - Type '{ isOnboardingEnabled: boolean; renderOnboardingSpotlight: () => null; availableSpotlightTargets: never[]; onAdd: (...args: any[]) => void; onRetry: (...args: any[]) => void; }' is not assignable to type 'undefined'.
)(Content);

// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ComponentProps } from 'react';
import type { Store } from 'redux';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { ViewTracker } from '@atlassian/jira-analytics-web-react/src';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import { ReportErrors } from '@atlassian/jira-errors-handling';
import { FlagsDispatcher } from '@atlassian/jira-flags';
import flagsMapper from './flags';
import type { Props } from './model';
import { initialQuery } from './model/constants';
import rootEpic from './ops';
import createStore from './state';
import { setAppProps } from './state/actions/app';
import { retrieve as retrieveBoards, retrieved as retrievedBoards } from './state/actions/boards';
import { setQuery } from './state/actions/query';
import { getQuery } from './state/selectors/query';
import type { State } from './state/types';
import View from './view';

// eslint-disable-next-line jira/react/no-class-components
export default class Boards extends Component<Props> {
	static defaultProps = {
		query: undefined,
		prefetchedData: {},
		withPageReadyMetrics: true,
		onQueryChanged: noop,
		onUnmount: noop,
	};

	constructor(props: Props) {
		super(props);

		this.store = createStore(rootEpic);
		const { boards } = props.prefetchedData;
		const { dispatch } = this.store;

		dispatch(setAppProps(props));
		dispatch(setQuery(props.query || initialQuery));
		dispatch(boards ? retrievedBoards(boards) : retrieveBoards());
	}

	componentDidUpdate(prevProps: Props) {
		const { dispatch } = this.store;
		const { query } = this.props;

		if (!isEqual(prevProps, this.props)) {
			dispatch(setAppProps(this.props));
		}

		if (query && !isEqual(query, getQuery(this.store.getState()))) {
			dispatch(setQuery(query));
			dispatch(retrieveBoards());
		}
	}

	componentWillUnmount() {
		this.props.onUnmount();
	}

	store: Store<State>;

	render() {
		return (
			<AppBase store={this.store} hasErrorBoundary={false}>
				<ReportErrors id="unhandled" packageName="jiraBoardsMain" teamName="jira-cosmos">
					{/* @ts-expect-error - TS2322 - Type '(action: Action, state: State) => { type: string; title: { id: string; defaultMessage: string; description: string; }; } | { type: string; title: ({ id: string; defaultMessage: string; description: string; } | { ...; })[]; } | undefined' is not assignable to type 'FlagsMapper<Action, State>'. */}
					<FlagsDispatcher mapper={flagsMapper} />
					<ViewTracker />
					<AppStyle>
						{/* @ts-expect-error - TS2786 - Type 'any' is not assignable to type 'never'. */}
						<View />
					</AppStyle>
				</ReportErrors>
			</AppBase>
		);
	}
}

export type BoardsProps = JSX.LibraryManagedAttributes<
	typeof Boards,
	ComponentProps<typeof Boards>
>;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editAction: {
		id: 'directories.boards-v2.main.content.cells.actions.edit-action',
		defaultMessage: 'Edit settings',
		description:
			'Label displayed in the boards directory, when we click on meatball menu of the board list item. To edit the settings of an existing board.',
	},
	deleteAction: {
		id: 'directories.boards-v2.main.content.cells.actions.delete-action',
		defaultMessage: 'Delete',
		description:
			'Label displayed in the boards directory, when we click on meatball menu of the board list item. To delete an existing board.',
	},
	copyAction: {
		id: 'directories.boards-v2.main.content.cells.actions.copy-action',
		defaultMessage: 'Copy',
		description:
			'Label displayed in the boards directory, when we click on meatball menu of the board list item. To copy an existing board.',
	},
	moveAction: {
		id: 'directories.boards-v2.main.content.cells.actions.move-action',
		defaultMessage: 'Move',
		description:
			'Label displayed in the boards directory, when we click on meatball menu of the board list item. To move an existing board to another location, i.e. under another project or the current user.',
	},
	tooltip: {
		id: 'directories.boards-v2.main.content.cells.actions.tooltip',
		defaultMessage: 'More',
		description: 'Tooltip for the meatball menu.',
	},
});

import { type Epic, combineEpics } from 'redux-observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import defaultsDeep from 'lodash/defaultsDeep';
import { Observable } from 'rxjs/Observable';
import { PATCH_QUERY, SET_QUERY } from '../../state/actions/query';
import type { State } from '../../state/reducer/types';
import { getOnQueryChanged } from '../../state/selectors/app';
import { getQuery } from '../../state/selectors/query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const querySet: Epic<any, State> = (action$, store) =>
	action$.ofType(SET_QUERY).mergeMap(({ payload }) => {
		const state = store.getState();
		const callback = getOnQueryChanged(state);

		if (typeof callback === 'function') {
			callback(payload);
		}

		return Observable.empty<never>();
	});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const queryPatched: Epic<any, State> = (action$, store) =>
	action$.ofType(PATCH_QUERY).mergeMap(({ payload }) => {
		const state = store.getState();
		const callback = getOnQueryChanged(state);

		if (typeof callback === 'function') {
			callback(defaultsDeep(payload, getQuery(state)));
		}

		return Observable.empty<never>();
	});

export default combineEpics(querySet, queryPatched);

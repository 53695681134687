// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import withProps from '@atlassian/jira-common-with-props';
import createBoardLegacy from '@atlassian/jira-create-board-legacy/src';
import CreateDialogLoader from './view';

const showCreateBoardDialog: () => Promise<void> = () =>
	createBoardLegacy().catch((error: Error) =>
		log.safeErrorWithoutCustomerData(
			'directories.boards-v2.create-button.create-dialog-loader',
			error ? error.message : '',
			error,
		),
	);

export default withProps({
	showCreateBoardDialog,
	// @ts-expect-error - TS2345 - Argument of type 'typeof CreateDialogLoader' is not assignable to parameter of type 'AbstractComponent<{ showCreateBoardDialog: () => Promise<void>; }, unknown>'.
})(CreateDialogLoader);

export type Props = Flow.Diff<
	JSX.LibraryManagedAttributes<
		typeof CreateDialogLoader,
		ComponentProps<typeof CreateDialogLoader>
	>,
	{
		showCreateBoardDialog: () => Promise<void>;
	}
>;

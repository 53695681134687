import { initialQuery } from '../../../model/constants';
import { SET_QUERY, PATCH_QUERY, type Action } from '../../actions/query';
import type { QueryState } from './types';

export const initialState: QueryState = initialQuery;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: QueryState = initialState, action: Action): QueryState => {
	switch (action.type) {
		case SET_QUERY:
			return action.payload;
		case PATCH_QUERY:
			return {
				...state,
				...action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

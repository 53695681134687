export interface ClassicLocationlessData {
	classicLocationless?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedLocation?: any;
	boardId?: number;
}

export const getClassicLocationlessRedirectUrl = (
	classicLocationlessData: ClassicLocationlessData,
): string | null => {
	const { classicLocationless, selectedLocation, boardId } = classicLocationlessData ?? {};
	if (
		classicLocationless &&
		(selectedLocation?.location?.key || selectedLocation?.location?.name) &&
		boardId
	) {
		const relUrl =
			selectedLocation.type === 'project'
				? `/jira/software/c/projects/${selectedLocation.location.key}/boards/${boardId}`
				: `/jira/people/${selectedLocation.location.name}/boards/${boardId}`;

		return window.location.origin + relUrl;
	}

	return null;
};

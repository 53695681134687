import { connect } from '@atlassian/jira-react-redux';
import { storeOptions } from '../../../common/remapped-redux';
import type { State } from '../../../state';
import { entityCreated, entityCreateFailed, cancelled } from '../../../state/actions/create';
import { getCreateModal } from '../../../state/selectors';
import Create from './view';

const mapStateToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => {
	const CreateModal = getCreateModal(state);
	if (!CreateModal) {
		throw new Error('Invalid state - no Create Modal provided');
	}
	return {
		CreateModal,
	};
};

const mapDispatchToProps = {
	onCreated: entityCreated,
	onCreateFailed: entityCreateFailed,
	onCancel: cancelled,
} as const;

export default connect(mapStateToProps, mapDispatchToProps, null, storeOptions)(Create);

import { connect } from '@atlassian/jira-react-redux';
import { getBoard, getSprintInformation } from '../../state/selectors';
import type { State } from '../../state/types';
import Content from './view';

const mapStateToProps = (state: State) => ({
	board: getBoard(state),
	sprintInformation: getSprintInformation(state),
});

// @ts-expect-error - Argument of type 'ComponentType<WithIntlProps<Props>>' is not assignable to parameter of type 'ComponentType<Matching<{ board: Board; sprintInformation: SprintInformation | undefined; } & {}, WithIntlProps<Props>>>'.
export default connect(mapStateToProps, {})(Content);

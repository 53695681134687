import { createSelector } from 'reselect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contextSelector = (state: any) => state.context;

/** @deprecated baseUrl in cloud is an empty string. remove code that checks or uses it. */
export const baseUrlSelector = (_: unknown): string => '';

export const rapidViewIdSelector = createSelector(
	contextSelector,
	(context) => context.rapidViewId,
);

export const currentLocationSelector = createSelector(
	contextSelector,
	(context) => context.currentLocation,
);

export const currentLocationDisplayNameSelector = createSelector(
	currentLocationSelector,
	(location) => location.displayName,
);

export const currentLocationAvatarSelector = createSelector(
	currentLocationSelector,
	(location) => location.avatar,
);

import { chain, assoc, assocIn, merge } from 'icepick';
import * as actions from '../action-types';
import { defaultState as initialState } from '../default-state';

// @ts-expect-error - TS7031 - Binding element 'type' implicitly has an 'any' type. | TS7031 - Binding element 'payload' implicitly has an 'any' type.
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.INIT_DIALOG:
			// @ts-expect-error - TS2554 - Expected 3 arguments, but got 2.
			return assoc(state, initialState);
		case actions.SET_CONTEXT:
			return assocIn(state, ['context'], payload);
		case actions.SELECT_LOCATION:
			return merge(state, {
				errorOnLocationSet: false,
				isLocationSet: false,
				selectedLocation: payload,
			});
		case actions.UNSELECT_LOCATION:
			return chain(state)
				.assoc('errorOnLocationSet', false)
				.assoc('isLocationSet', false)
				.assoc('selectedLocation', {})
				.value();
		case actions.FETCH_LOCATIONS_DATA_SUCCESS:
			return assocIn(state, ['locations'], payload);
		case actions.SET_LOCATION_SUCCESS:
			return merge(state, {
				errorOnLocationSet: false,
				isLocationSet: true,
				isSettingLocation: false,
			});
		case actions.SET_LOCATION_FAILURE:
			return merge(state, {
				errorOnLocationSet: true,
				isLocationSet: false,
				isSettingLocation: false,
			});
		case actions.FETCH_DATA_FAILURE:
			return merge(state, {
				errorOnFetchData: true,
			});
		case actions.CANCEL_DIALOG:
			return chain(state)
				.assoc('errorOnLocationSet', false)
				.assoc('isLocationSet', false)
				.assoc('selectedLocation', {})
				.value();
		case actions.SET_NEW_LOCATION:
			return merge(state, {
				errorOnLocationSet: false,
				isLocationSet: false,
				isSettingLocation: true,
			});
		default:
			break;
	}

	return state;
};

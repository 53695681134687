import React from 'react';
import Blanket from '@atlaskit/blanket';
import Spinner from '@atlaskit/spinner';
import { Wrapper, SpinnerWrapper } from './styled';

const LoadingScreen = () => (
	<Wrapper>
		<Blanket isTinted />
		<SpinnerWrapper>
			<Spinner size="medium" />
		</SpinnerWrapper>
	</Wrapper>
);

export default LoadingScreen;

import React, { type ComponentType } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/standard-button';
import ModalDialog, {
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { ViewTracker } from '@atlassian/jira-analytics-web-react/src';
import type { Board } from '@atlassian/jira-boards-common/src/model';
import { ShortcutScope } from '@atlassian/jira-common-components-keyboard-shortcuts';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import type { SprintInformation } from '../model';
import messages from './messages';

export type Props = {
	board: Board;
	sprintInformation: SprintInformation | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Content: ComponentType<Record<any, any>>;
	deleteInProgress: boolean;
	onCancel: () => void;
	onDelete: () => void;
	intl: IntlShape;
};

function DeleteBoardsModal(props: Props) {
	const { Content, board, sprintInformation, deleteInProgress, onCancel, onDelete } = props;
	const {
		intl: { formatMessage },
	} = props;

	if (!sprintInformation) {
		return null;
	}
	const onCancelOrNoop = !deleteInProgress ? onCancel : noop;

	// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'.
	const heading = board.isPrivate
		? formatMessage(messages.deletePrivateBoard)
		: // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Board'.
			formatMessage(messages.deleteBoard, { boardName: board.name });

	return (
		<ShortcutScope>
			<ModalDialog onClose={onCancelOrNoop}>
				<ModalHeader>
					<ModalTitle appearance="danger">{heading}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Content />
					<ViewTracker />
				</ModalBody>
				<ModalFooter>
					<Button appearance="danger" onClick={onDelete} isDisabled={deleteInProgress}>
						{formatMessage(messages.deleteCaption)}
					</Button>
					<Button appearance="subtle-link" onClick={onCancelOrNoop} isDisabled={deleteInProgress}>
						{formatMessage(messages.cancel)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}

DeleteBoardsModal.defaultProps = {
	deleteInProgress: false,
	onCancel: noop,
	onDelete: noop,
};

export default DeleteBoardsModal;

import { ASC } from '@atlassian/jira-common-constants/src/sort-directions';
import type { ExpandList } from '@atlassian/jira-shared-types';
import { NAME } from './sort-field';
import type { Query } from './index';

export const ITEMS_PER_PAGE = 25;
export const EXPAND_PARAMETERS: ExpandList = ['admins', 'permissions', 'favourite'];

export const initialQuery: Query = {
	sortField: NAME,
	sortDirection: ASC,
	page: 1,
	filter: {
		text: '',
	},
};

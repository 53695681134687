export const defaultState = {
	isLocationSet: false,
	errorOnLocationSet: false,
	errorOnFetchData: false,
	selectedLocation: {},
	context: undefined,
	locations: {},
	recentProjects: 5,
	isSettingLocation: false,
} as const;

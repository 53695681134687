import pick from 'lodash/pick';
import { stringify } from 'query-string';
import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { getErrorFromResponse } from '@atlassian/jira-common-directory-v2/src/rest/errors';
import { ff } from '@atlassian/jira-feature-flagging';
import type { PageRequest } from '@atlassian/jira-shared-types';
import type { Filter } from '../../../model';
import type { Response } from './types';

export const createEndpointPath = () => '/rest/agile/1.0/board';

export const createSearchQuery = (
	filter: Filter,
): {
	name?: string | undefined;
	searchQuery?: string;
	searchBy?: string;
} => ({
	name: filter.text || undefined,
});

export const createUrl = (
	baseUrl: string,
	request: PageRequest,
	filter: Filter,
	includePrivate: boolean,
) => {
	const queryParams = {
		...pick(request, 'startAt', 'maxResults', 'orderBy', 'expand'),
		...(filter.text ? createSearchQuery(filter) : undefined),
		includePrivate,
		...(ff('show-private-boards-to-admins-in-search-results_z9oud') && {
			overrideFilterPermissions: true,
		}),
	};

	return `${createEndpointPath()}?${stringify(queryParams)}`;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	baseUrl: string,
	request: PageRequest,
	filter: Filter,
	includePrivate: boolean,
): Promise<Response> => {
	const url = createUrl(baseUrl, request, filter, includePrivate);
	const response = await fetch(url, defaultOptions);

	if (response.ok) {
		return response.json();
	}

	throw await getErrorFromResponse(response, 'Cannot fetch boards');
};

import React, { type ComponentType, type FC } from 'react';
import type { Props as CreateDialogLoaderProps } from './create-dialog-loader';
import type { Props as StatelessButtonProps } from './stateless-button';

type Props = {
	CreateDialogLoader: ComponentType<CreateDialogLoaderProps>;
	StatelessButton: ComponentType<StatelessButtonProps>;
};

const CreateBoardsButton: FC<Props> = ({ CreateDialogLoader, StatelessButton }: Props) => (
	<CreateDialogLoader>
		{(load, isLoading) => <StatelessButton onClick={load} isDisabled={isLoading} />}
	</CreateDialogLoader>
);

export default CreateBoardsButton;

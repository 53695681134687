import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	singleSprintMessage: {
		id: 'directories.boards-v2.delete-modal.content.single-sprint-message',
		defaultMessage:
			'This board contains issues from {sprintName}, and that sprint is currently active.',
		description:
			'An informational message that is displayed in the delete modal to inform the user that the board to be deleted contains one active sprint. The message also specifies the name of the sprint.',
	},
	multipleSprintsMessage: {
		id: 'directories.boards-v2.delete-modal.content.multiple-sprints-message',
		defaultMessage: 'This board contains issues from these active sprints:',
		description:
			'An informational message that is displayed in the delete modal to inform the user that the board to be deleted contains multiple active sprints. The message also specifies the name of these sprints.',
	},
	retrievalFailedMessage: {
		id: 'directories.boards-v2.delete-modal.content.retrieval-failed-message',
		defaultMessage:
			"Just a heads up, we're having trouble getting information about active sprints. Issues on {boardName} might be part of one.",
		description:
			'An informational message that is displayed in the delete modal to inform the user that we are unable to get active sprints information about the board that is about to be deleted.',
	},
	activeSprintsRecommendation: {
		id: 'directories.boards-v2.delete-modal.content.active-sprints-recommendation',
		defaultMessage:
			'We recommend completing or deleting active sprints before deleting {boardName}.',
		description:
			'Additional message being displayed in the delete modal whenever the board to be deleted contains one or more active sprints. It recommends that these sprints should be completed first before deleting the board.',
	},
	noSprintsMessage: {
		id: 'directories.boards-v2.delete-modal.content.no-sprints-message',
		defaultMessage:
			"Deleting {boardName} doesn't affect the issues on the board. And, it won't complete or delete any active sprints.",
		description:
			'Generic informational message that is displayed in the delete modal when the board has no active sprints.',
	},
	privateBoardMessage: {
		id: 'directories.boards-v2.delete-modal.content.private-board-message',
		defaultMessage:
			"Deleting this board doesn't affect the issues on the board. And, it won't complete or delete any active sprints.",
		description:
			'Generic informational message that is displayed in the delete modal when the board is private and therefore we are unable to find out about its active sprints.',
	},
	link: {
		id: 'directories.boards-v2.delete-modal.content.link',
		defaultMessage: 'Learn more about running sprints.',
		description:
			'Label for a link that points to a documentation on how to run sprints. This link is displayed in the delete modal when the board has one or more active sprints.',
	},
});

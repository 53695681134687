import type { Props } from '../../../model';

// SET APP PROPS

export const SET_APP_PROPS = 'state.actions.app.SET_APP_PROPS' as const;

export type SetAppPropsAction = {
	type: typeof SET_APP_PROPS;
	payload: Props;
};

export const setAppProps = (payload: Props): SetAppPropsAction => ({
	type: SET_APP_PROPS,
	payload,
});

export const READY = 'state.actions.app.READY' as const;
export type ReadyAction = {
	type: typeof READY;
};
export const ready = (): ReadyAction => ({
	type: READY,
});

export type Action = SetAppPropsAction | ReadyAction;

import type {
	Board,
	PublicBoard,
	OperationFailureReason,
	CopiedBoardSummary,
} from '@atlassian/jira-boards-common/src/model';

// DELETED

export const DELETED = 'state.actions.operations.DELETED' as const;

export type DeletedPayload = {
	board: Board;
};

export type DeletedAction = {
	type: typeof DELETED;
	payload: DeletedPayload;
};

export const deleted = (board: Board): DeletedAction => ({
	type: DELETED,
	payload: { board },
});

// DELETE_FAILED

export const DELETE_FAILED = 'state.actions.operations.DELETE_FAILED' as const;

export type DeleteFailedPayload = DeletedPayload & {
	reason: OperationFailureReason;
};

export type DeleteFailedAction = {
	type: typeof DELETE_FAILED;
	payload: DeleteFailedPayload;
};

export const deleteFailed = (board: Board, reason: OperationFailureReason): DeleteFailedAction => ({
	type: DELETE_FAILED,
	payload: {
		board,
		reason,
	},
});

// COPIED

export const COPIED = 'state.actions.operations.COPIED' as const;

export type CopiedPayload = {
	board: PublicBoard;
	copiedBoardSummary: CopiedBoardSummary;
};

export type CopiedAction = {
	type: typeof COPIED;
	payload: CopiedPayload;
};

export const copied = (
	board: PublicBoard,
	copiedBoardSummary: CopiedBoardSummary,
): CopiedAction => ({
	type: COPIED,
	payload: { board, copiedBoardSummary },
});

// COPY_FAILED

export const COPY_FAILED = 'state.actions.operations.COPY_FAILED' as const;

export type CopyFailedPayload = {
	board: PublicBoard;
	reason: OperationFailureReason;
};

export type CopyFailedAction = {
	type: typeof COPY_FAILED;
	payload: CopyFailedPayload;
};

export const copyFailed = (
	board: PublicBoard,
	reason: OperationFailureReason,
): CopyFailedAction => ({
	type: COPY_FAILED,
	payload: {
		board,
		reason,
	},
});

// MOVED

export const MOVED = 'state.actions.operations.MOVED' as const;

export type MovedPayload = {
	board: Board;
};

export type MovedAction = {
	type: typeof MOVED;
	payload: MovedPayload;
};

export const moved = (board: Board): MovedAction => ({
	type: MOVED,
	payload: { board },
});

// MOVE_FAILED

export const MOVE_FAILED = 'state.actions.operations.MOVE_FAILED' as const;

export type MoveFailedPayload = {
	board: Board;
};

export type MoveFailedAction = {
	type: typeof MOVE_FAILED;
	payload: MoveFailedPayload;
};

export const moveFailed = (board: Board): MoveFailedAction => ({
	type: MOVE_FAILED,
	payload: {
		board,
	},
});

export type Action =
	| DeletedAction
	| DeleteFailedAction
	| CopiedAction
	| CopyFailedAction
	| MovedAction
	| MoveFailedAction;

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import getLocations$ from '../../../../../requests/get-locations';
import { fetchLocationsDataSuccess, fetchDataFailure } from '../../action-creators';
import { FETCH_DATA_REQUEST } from '../../action-types';
import { baseUrlSelector, recentProjectsSelector } from '../../selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: any, store: any) =>
	action$.ofType(FETCH_DATA_REQUEST).switchMap(() => {
		const state = store.getState();
		return (
			getLocations$(baseUrlSelector(state), recentProjectsSelector(state))
				.map(fetchLocationsDataSuccess)
				// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
				.catch((anotherError) => Observable.of(fetchDataFailure(anotherError)))
		);
	});

import memoizeOne from 'memoize-one';
import { connect } from '../../../../../common/remapped-redux';
import type { FilterViewProps } from '../../../../../model';
import type { State } from '../../../../../state';
import { getHeaderConfiguration, getOnReload } from '../../../../../state/selectors';
import Filter from './view';

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(): ((state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
	FilterView: Flow.AbstractComponent<FilterViewProps<TFilter>, unknown>;
	onFilter: (filter: never) => void;
}) => {
	const onFilterFactory = memoizeOne(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => (filter: never) =>
			getOnReload(state)({ page: 1, filter }),
	);
	return (state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
		const headerConfiguration = getHeaderConfiguration(state);
		// @ts-expect-error - TS2339 - Property 'FilterView' does not exist on type 'HeaderConfiguration<TFilter>'.
		if (!headerConfiguration || !headerConfiguration.FilterView) {
			throw new Error('Invalid state - Filter wrapper must not be mounted');
		}

		return {
			// @ts-expect-error - TS2339 - Property 'FilterView' does not exist on type 'HeaderConfiguration<TFilter>'.
			FilterView: headerConfiguration.FilterView,
			onFilter: onFilterFactory(state),
		};
	};
};

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2. | TS2345 - Argument of type 'typeof Filter' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
export default connect(mapStateToPropsFactory, {})(Filter);

import type { Store } from 'redux';
import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate';
import type { Action } from './actions';
import rootReducer from './reducer';
import type { State } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (rootEpic?: Epic<Action, State>): Store<State> =>
	initiateStore<State, Action, undefined>({
		appName: 'directories/boards-v2/copy-modal',
		rootReducer,
		rootEpic,
	});

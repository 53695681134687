import { freeze, assocIn } from 'icepick';
import { SET_LOCATION_SUCCESS } from '../new-location/new-location-action-type';
import { CANCEL_DIALOG, INIT_DIALOG } from './dialog-action-type';

const initialState = freeze({
	isOpen: true,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state = initialState, action: any) => {
	const { type } = action;

	switch (type) {
		case INIT_DIALOG:
			return assocIn(state, ['isOpen'], true);
		case CANCEL_DIALOG:
		case SET_LOCATION_SUCCESS:
			return assocIn(state, ['isOpen'], false);
		default:
			return state;
	}
};

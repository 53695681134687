import { RETRIEVE, RETRIEVED, RETRIEVE_FAILED, PATCH, type Action } from '../../actions/boards';
import type { BoardsState } from './types';

export const initialState: BoardsState = {
	page: {},
	order: [],
	total: 0,
	isLoading: true,
	isError: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: BoardsState = initialState, action: Action): BoardsState => {
	switch (action.type) {
		case RETRIEVE: {
			return {
				...state,
				isLoading: true,
			};
		}

		case RETRIEVED: {
			return {
				...state,
				...action.payload,
				isLoading: false,
				isError: false,
			};
		}

		case RETRIEVE_FAILED: {
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		}

		case PATCH:
			return {
				...state,
				// @ts-expect-error - 2345 - Type 'undefined' is not assignable to type 'string'
				page: {
					...state.page,
					[action.payload.id]: {
						...state.page[action.payload.id],
						...action.payload.patch,
					},
				},
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

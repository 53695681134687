import { freeze, merge } from 'icepick';
import { SET_LOCATION_SUCCESS, SET_LOCATION_FAILURE } from './new-location-action-type';

const initialState = freeze({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state = initialState, action: any) => {
	const { type, payload } = action;

	switch (type) {
		case SET_LOCATION_SUCCESS:
		case SET_LOCATION_FAILURE:
			return merge(state, payload);
		default:
			return state;
	}
};

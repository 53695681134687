import { Component, type ReactElement } from 'react';

type Props = {
	// eslint-disable-next-line jira/react/handler-naming
	showCreateBoardDialog: () => Promise<void>;
	children: (load: () => void, isLoading: boolean) => ReactElement;
};

type State = {
	isLoading: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class CreateDialogLoader extends Component<Props, State> {
	state = {
		isLoading: false,
	};

	onCreateBoardDialogLoaded = () => this.setLoadingState(false);

	setLoadingState(isLoading: boolean) {
		this.setState({
			isLoading,
		});
	}

	// eslint-disable-next-line jira/react-arrow-function-property-naming
	load = () => {
		if (this.state.isLoading) {
			return;
		}

		this.setLoadingState(true);

		const { showCreateBoardDialog } = this.props;
		showCreateBoardDialog().finally(this.onCreateBoardDialogLoaded);
	};

	render() {
		const { children } = this.props;
		const { isLoading } = this.state;

		return children(this.load, isLoading);
	}
}

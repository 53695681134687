// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import flow from 'lodash/flow';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import StatelessButton from './view';

export default flow(withFireUiAnalytics({ onClick: 'createBoard' }), injectIntl)(StatelessButton);

export type Props = Flow.Diff<
	JSX.LibraryManagedAttributes<typeof StatelessButton, ComponentProps<typeof StatelessButton>>,
	{
		intl: IntlShape;
	}
>;

import flow from 'lodash/flow';
import { withFireUiAnalytics, ReduxAnalyticsData } from '@atlassian/jira-analytics-web-react/src';
import { SIMPLE } from '@atlassian/jira-common-directory-v2/src/model/constants';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../../model';
import { getBaseUrl } from '../../../../../state/selectors/app';
import {
	getName,
	getType,
	getLocation,
	getRapidBoardUrlForSavedBoard,
} from '../../../../../state/selectors/boards';
import type { State } from '../../../../../state/types';
import Public from './view';

const getNextGenBoardPrettyUrl = (state: State, id: string): string | null => {
	const location = getLocation(state, id);
	// @ts-expect-error - TS2339 - Property 'projectKey' does not exist on type 'Location'.
	if (location && location.projectKey) {
		// @ts-expect-error - TS2339 - Property 'projectKey' does not exist on type 'Location'.
		return `${getBaseUrl(state)}/jira/software/projects/${location.projectKey}/boards/${id}`;
	}
	return null;
};

export const getBoardUrl = (state: State, id: string): string | null => {
	if (getType(state, id) === SIMPLE) {
		return getNextGenBoardPrettyUrl(state, id);
	}

	return getRapidBoardUrlForSavedBoard(state, id);
};

const mapStateToProps = () => (state: State, ownProps: EntityCellProps) => ({
	caption: getName(state, ownProps.id),
	href: getBoardUrl(state, ownProps.id),
	type: getType(state, ownProps.id),
});

export default flow(
	ReduxAnalyticsData((state: State, ownProps: EntityCellProps) => ({
		attributes: {
			boardType: getType(state, ownProps.id),
		},
	})),
	withFireUiAnalytics({
		onClick: 'board',
	}),
	connect(mapStateToProps, {}),
)(Public);

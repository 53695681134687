import React from 'react';
import memoizeOne from 'memoize-one';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react/src';
import FilterSearch from '@atlassian/jira-common-analytics-v2-wrapped-components/src/filter-search';
import type { FilterViewProps } from '@atlassian/jira-directory-base/src/model';
import { connect } from '@atlassian/jira-react-redux';
import type { Filter as FilterType } from '../../../model';
import { getFilter } from '../../../state/selectors/query';
import type { State } from '../../../state/types';
import Filter from './view';

const FilterSearchWithAnalytics = withFireUiAnalytics({
	onChange: 'filterSearch',
})(FilterSearch);

const mapStateToPropsFactory = () => {
	// @ts-expect-error - TS7006 - Parameter 'onChange' implicitly has an 'any' type.
	const renderFilterSearchFactory = memoizeOne((filter: FilterType) => (onChange) => (
		/* @ts-expect-error - Type '{ value: string; onChange: any; isAlwaysExpanded: true; }' is missing the following properties from type 'Omit<{ value: string; placeholder: string; describedById?: string | undefined; hasAutoFocus: boolean; isCompact: boolean; onChange: (value: string, args_1: UIAnalyticsEvent) => void; ... 6 more ...; key?: Key | ... 1 more ... | undefined; }, keyof WithAnalyticsEventsProps>': placeholder, hasAutoFocus, isCompact, increasedWidth, placeholderAlwaysVisible */
		<FilterSearchWithAnalytics value={filter.text} onChange={onChange} isAlwaysExpanded />
	));
	return (state: State, ownProps: FilterViewProps<FilterType>) => ({
		value: getFilter(state),
		onChange: ownProps.onFilter,
		renderFilterSearch: renderFilterSearchFactory(getFilter(state)),
	});
};
export default connect(mapStateToPropsFactory, {})(Filter);

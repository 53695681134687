import { SET_APP_PROPS, COPY_BOARD, BOARD_COPIED, COPY_BOARD_FAILED, type Action } from './actions';
import type { State } from './types';

const defaultState: State = {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	appProps: undefined as any, // initiatized immediately
	isCopyInProgress: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case SET_APP_PROPS:
			return {
				...defaultState,
				appProps: action.payload,
			};

		case COPY_BOARD:
			return {
				...state,
				isCopyInProgress: true,
			};

		case COPY_BOARD_FAILED:
			return {
				...state,
				isCopyInProgress: false,
			};

		case BOARD_COPIED:
			return state;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

import { isPrivateBoard } from '@atlassian/jira-boards-common';
import type {
	Board,
	BoardType,
	Location,
	BoardAdmins,
	CopiedBoardLocation,
} from '@atlassian/jira-boards-common/src/model';
import type { State } from '../../types';
import { getFavouriteChangeContext, getBaseUrl } from '../app';

export const isEmpty = (state: State): boolean => state.boards.order.length === 0;
export const getIds = (state: State): string[] => state.boards.order;
export const getTotal = (state: State): number => state.boards.total;
export const isLoading = (state: State): boolean => state.boards.isLoading;
export const isError = (state: State): boolean => state.boards.isError;

export const getBoard = (state: State, id: string): Board | undefined => state.boards.page[id];

export const isPrivate = (state: State, id: string): boolean => {
	const board = state.boards?.page[id];
	return board ? isPrivateBoard(board) : false;
};

export const getName = (state: State, id: string): string | undefined => {
	const board = getBoard(state, id);
	if (isPrivateBoard(board)) {
		throw new Error('Invalid access to name of private board.');
	}
	return board?.name;
};

export const getType = (state: State, id: string): BoardType | undefined => {
	const board = getBoard(state, id);
	if (isPrivateBoard(board)) {
		throw new Error('Invalid access to type of private board.');
	}
	return board?.type;
};

export const getLocation = (state: State, id: string): Location | undefined =>
	state.boards.page[id]?.location;

export const getAdministrators = (state: State, id: string): BoardAdmins | undefined =>
	state.boards.page[id]?.admins;

export const canEdit = (state: State, id: string): boolean =>
	state.boards.page[id]?.canEdit || false;

export const getLegacyRapidBoardUrl = (state: State, id: string): string =>
	`/secure/RapidBoard.jspa?rapidView=${id}&useStoredSettings=true`;

const getRapidBoardUserBoardUrl = (baseUrl: string, userAccountId: string, boardId: string) =>
	`/jira/people/${userAccountId}/boards/${boardId}`;

const getRapidBoardProjectBoardUrl = (baseUrl: string, projectKey: string, boardId: string) =>
	`/jira/software/c/projects/${projectKey}/boards/${boardId}`;

export const getRapidBoardUrlForCopiedBoard = (
	state: State,
	id: string,
	location: CopiedBoardLocation,
): string => {
	const baseUrl = getBaseUrl(state);

	if (!location.isValid) {
		return `${getLegacyRapidBoardUrl(state, id)}&locationless=true`;
	}

	if (location.accountId != null) {
		return getRapidBoardUserBoardUrl(baseUrl, location.accountId, id);
	}

	return getRapidBoardProjectBoardUrl(baseUrl, location.locationKey, id);
};

export const getRapidBoardEditUrlForCopiedBoard = (
	state: State,
	id: string,
	location: CopiedBoardLocation,
): string => {
	const baseUrl = getBaseUrl(state);
	const editQuery = 'config=default';

	if (!location.isValid) {
		return `${getLegacyRapidBoardUrl(state, id)}&locationless=true&${editQuery}`;
	}

	if (location.accountId != null) {
		return `${getRapidBoardUserBoardUrl(baseUrl, location.accountId, id)}?${editQuery}`;
	}

	return `${getRapidBoardProjectBoardUrl(baseUrl, location.locationKey, id)}?${editQuery}`;
};

export const getRapidBoardUrlForSavedBoard = (state: State, id: string): string => {
	const location = getLocation(state, id);
	const baseUrl = getBaseUrl(state);

	// @ts-expect-error - TS2339 - Property 'projectKey' does not exist on type 'Location'.
	if (location?.projectKey != null) {
		// @ts-expect-error - TS2339 - Property 'projectKey' does not exist on type 'Location'.
		return getRapidBoardProjectBoardUrl(baseUrl, location.projectKey, id);
	}
	// @ts-expect-error - TS2339 - Property 'userAccountId' does not exist on type 'Location'.
	if (location?.userAccountId != null) {
		// @ts-expect-error - TS2339 - Property 'userAccountId' does not exist on type 'Location'.
		return getRapidBoardUserBoardUrl(baseUrl, location.userAccountId, id);
	}

	return `${getLegacyRapidBoardUrl(state, id)}&locationless=true`;
};

export const getEditBoardUrl = (state: State, id: string): string => {
	const baseUrl = getBaseUrl(state);

	const location = getLocation(state, id);
	if (location?.type === 'project') {
		return `${getRapidBoardProjectBoardUrl(baseUrl, location.projectKey, id)}?config=default`;
	}
	if (location?.type === 'user') {
		return `${getRapidBoardUserBoardUrl(baseUrl, location.userAccountId, id)}?config=default`;
	}

	return `${getBaseUrl(
		state,
	)}/secure/RapidBoard.jspa?rapidView=${id}&config=default&locationless=true`;
};

const getFavouriteChangeContextItem = (state: State, id: string) =>
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
	getFavouriteChangeContext(state).items.boards[id];

export const isFavourite = (state: State, id: string): boolean | undefined => {
	const board = getBoard(state, id);
	if (isPrivateBoard(board)) {
		throw new Error("Invalid access to 'favourite' state of private board.");
	}
	const itemChanged = getFavouriteChangeContextItem(state, id);
	return itemChanged?.value || board?.favourite;
};

export const isPending = (state: State, id: string): boolean => {
	const item = getFavouriteChangeContextItem(state, id);
	return !!(item && item.pending);
};

export const getChangeFavourite = (state: State) =>
	getFavouriteChangeContext(state).changeFavourite;

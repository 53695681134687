import type { Observable } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-fetch';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, boardId: string): Observable<void> => {
	const url = `/rest/agile/1.0/board/${boardId}`;
	const options = {
		method: 'delete',
	};
	return fetchJson$(url, options);
};

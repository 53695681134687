import type { State } from './model';

export const defaultState: State = {
	locations: {
		projects: [],
		// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'Location': displayName, name, id, avatar
		user: {},
	},
	context: {
		baseUrl: '',
		recentProjectsLimit: 0,
	},
};

import type { StringifiableValue } from '@atlassian/jira-common-bind-url-to-state';
import { ASC, DESC } from '@atlassian/jira-common-constants/src/sort-directions';

export const calculateTotalPages = (totalItems: number, itemsPerPage: number): number =>
	Math.max(Math.ceil(totalItems / itemsPerPage), 1);

export const predictPageToRequestAfterItemDeletion = (
	itemsPerPage: number,
	totalItems: number,
	currentPage = 1,
	itemsRemoved = 1,
) => {
	const maxPossiblePage = calculateTotalPages(totalItems - itemsRemoved, itemsPerPage);
	return currentPage > maxPossiblePage ? maxPossiblePage : currentPage;
};

export const validatePageNumber = (input: StringifiableValue) =>
	typeof input === 'number' && Number.isInteger(input) && input >= 1;

// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"ASC" | "DESC"'.
export const validateSortDirection = (input: string) => [ASC, DESC].includes(input);

import type { Dispatch } from 'redux';
import type {
	Board,
	OperationFailureReason,
	PublicBoard,
	CopiedBoardSummary,
} from '@atlassian/jira-boards-common/src/model';
import CopyModal from '@atlassian/jira-copy-boards-modal/src';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model';
import { connect } from '@atlassian/jira-react-redux';
import { type Action, copied, copyFailed } from '../../state/actions/operations';
import { getBaseUrl } from '../../state/selectors/app';
import { getBoard } from '../../state/selectors/boards';
import type { State } from '../../state/types';

const mapStateToProps = (state: State, ownProps: OperationModalProps<Board>) => {
	const board = getBoard(state, ownProps.entityId);
	// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'.
	if (board.isPrivate) {
		throw new Error("Unexpected state: Private board can't be copied.");
	}

	return {
		baseUrl: getBaseUrl(state),
		board,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OperationModalProps<Board>) => ({
	onInitialised: ownProps.onDialogShown,
	onBoardCopied: (board: PublicBoard, copiedBoardSummary: CopiedBoardSummary) => {
		dispatch(copied(board, copiedBoardSummary));
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		ownProps.onUpdated();
	},
	onBoardCopyFailed: (board: PublicBoard, reason: OperationFailureReason) =>
		dispatch(copyFailed(board, reason)),
	onCancel: ownProps.onCancel,
});

// @ts-expect-error - TS2345 - Argument of type 'typeof CopyModal' is not assignable to parameter of type 'Component<{ baseUrl: string; board: Board; } & { onInitialised: () => void; onBoardCopied: (board: PublicBoard, copiedBoardSummary: CopiedBoardSummary) => void; onBoardCopyFailed: (board: PublicBoard, reason: OperationFailureReason) => CopyFailedAction; onCancel: () => void; } & OperationModalProps<...>>'.
export default connect(mapStateToProps, mapDispatchToProps)(CopyModal);

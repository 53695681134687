import type { Dispatch } from 'redux';
import Directory from '@atlassian/jira-directory-base/src';
import { connect } from '@atlassian/jira-react-redux';
import type { Query } from '../model';
import { ITEMS_PER_PAGE } from '../model/constants';
import { ready } from '../state/actions/app';
import { patch as patchComponent, retrieve as retrieveComponents } from '../state/actions/boards';
import { patchQuery } from '../state/actions/query';
import { getIds, getTotal, isLoading, isError } from '../state/selectors/boards';
import {
	getSortField,
	getSortDirection,
	getPage,
	getFilter,
	isFilterApplied,
} from '../state/selectors/query';
import type { State, Action } from '../state/types';
import EmptyView from './content/empty';
import ErrorView from './content/error';
import FilterView from './content/filter';
import {
	operationModals,
	emptyFilterResultConfiguration,
	getTableConfiguration,
	getTableLabel,
	getResultsAnnouncerMessage,
} from './selectors';

const mapStateToProps = (state: State) => ({
	// data
	entityIds: getIds(state),
	totalItems: getTotal(state),
	itemsPerPage: ITEMS_PER_PAGE,
	isLoading: isLoading(state),
	isError: isError(state),
	page: getPage(state),
	sortField: getSortField(state),
	sortDirection: getSortDirection(state),
	filter: isFilterApplied(state) ? getFilter(state) : undefined,

	// ui
	firstColumnHeadingAlignment: 'center',

	// views
	FilterView,
	tableConfiguration: getTableConfiguration(state),
	tableLabel: getTableLabel(),
	resultsAnnouncerMessage: getResultsAnnouncerMessage(),
	emptyFilterResultConfiguration,
	operationModals,
	EmptyView,
	ErrorView,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
	// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type. | TS7031 - Binding element 'patch' implicitly has an 'any' type.
	onPatch: ({ id, patch }) => dispatch(patchComponent({ id, patch })),
	onReload: (query: Partial<Query>) => {
		dispatch(patchQuery(query));
		dispatch(retrieveComponents());
	},
	onFirstRender: () => dispatch(ready()),
});

// @ts-expect-error - TS2345 - Argument of type 'typeof Directory' is not assignable to parameter of type 'Component<{ entityIds: string[]; totalItems: number; itemsPerPage: number; isLoading: boolean; isError: boolean; page: number; sortField: "name"; sortDirection: SortDirection; filter: Filter | undefined; ... 7 more ...; ErrorView: ComponentClass<...> & { ...; }; } & { ...; }>'.
export default connect(mapStateToProps, mapDispatchToProps)(Directory);

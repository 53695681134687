import React, { type ComponentType } from 'react';
import type { FilterViewProps } from '../../../../../model';

type Props<TFilter> = {
	FilterView: ComponentType<FilterViewProps<TFilter>>;
} & FilterViewProps<TFilter>;

export default function Filter<TFilter>(props: Props<TFilter>) {
	const { FilterView, onFilter } = props;
	return <FilterView onFilter={onFilter} />;
}

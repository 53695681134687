import React from 'react';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src';
import BoardsApp from '@atlassian/jira-boards-page/src';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer';
import { ReportErrors } from '@atlassian/jira-errors-handling';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { SubmitApdex } from '@atlassian/jira-providers-spa-apdex-analytics/src';
import {
	appBundleReadyMark,
	prefetchApiReadyMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import { BoardsDirectorySkeleton } from '@atlassian/jira-skeletons';
import { PageLayout } from '@atlassian/jira-spa-apps-page-layout';
import { boardsDirectoryPageLoad } from '../utils/performance-analytics';
import type { Props } from './types';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

function BoardsDirectory(props: Props) {
	const { Updater, tenantContext, prefetchResult, favouriteChangeContext, query, onQueryChanged } =
		props;

	const { baseUrl, locale, xsrfToken, atlassianAccountId } = tenantContext;
	const { error: prefetchError, loading, data } = prefetchResult;

	if (prefetchError && !loading) {
		// @ts-expect-error - TS2322 - Type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)' is not assignable to type 'Error | null'.
		return <ErrorFlagRenderer id="directories-boards" error={prefetchError} />;
	}

	if (!data) {
		return <BoardsDirectorySkeleton />;
	}

	return (
		<PageLayout>
			<ReportErrors
				id="unhandled"
				packageName="jiraSpaAppsDirectoriesBoards"
				teamName="jira-cosmos"
			>
				<Updater />
				<BoardsApp
					isServer={__SERVER__}
					baseUrl={baseUrl}
					accountId={atlassianAccountId || null}
					locale={locale}
					xsrfToken={xsrfToken}
					query={query}
					favouriteChangeContext={favouriteChangeContext}
					prefetchedData={data}
					onQueryChanged={onQueryChanged}
					onUnmount={noop}
					withPageReadyMetrics={false}
				/>
				{/* @ts-expect-error - TS2769 - No overload matches this call. */}
				<SubmitApdex
					metricKey="jira.directories.boards"
					metric={boardsDirectoryPageLoad}
					isExpectedToHaveSsr
					withMarks={[appBundleReadyMark, prefetchApiReadyMark]}
				/>
			</ReportErrors>
		</PageLayout>
	);
}

export default AnalyticsSource('boards', SCREEN)(BoardsDirectory);

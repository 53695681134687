import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	isDisabled: boolean;
	withSpinner: boolean;
	onClick: () => void;
	intl: IntlShape;
};

const StatelessButton = (props: Props) => {
	const {
		intl: { formatMessage },
		onClick,
		isDisabled,
		withSpinner,
	} = props;

	const showSpinner = withSpinner && isDisabled;
	return (
		<Container>
			<Button
				onClick={onClick}
				appearance="primary"
				// @ts-expect-error - TS2322 - Type '{ children: string; onClick: () => void; appearance: "primary"; externalId: string; isDisabled: boolean; isLoading: boolean; }' is not assignable to type 'IntrinsicAttributes & ButtonProps & RefAttributes<HTMLElement>'.
				externalId="directory.boards-v2.create-button.stateless-button"
				isDisabled={isDisabled}
				isLoading={showSpinner}
			>
				{formatMessage(messages.createBoard)}
			</Button>
		</Container>
	);
};

StatelessButton.defaultProps = {
	withSpinner: true,
};

export default StatelessButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

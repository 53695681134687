import {
	SET_APP_PROPS,
	GET_ACTIVE_SPRINTS,
	ACTIVE_SPRINTS_RETRIEVED,
	GET_ACTIVE_SPRINTS_FAILED,
	DELETE_BOARD,
	BOARD_DELETED,
	DELETE_BOARD_FAILED,
	type Action,
} from './actions';
import type { State } from './types';

const defaultState: State = {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	appProps: undefined as any, // initiatized immediately
	sprintInformation: undefined,
	isDeleteInProgress: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case SET_APP_PROPS:
			return {
				...state,
				appProps: action.payload,
			};

		case DELETE_BOARD:
			return {
				...state,
				isDeleteInProgress: true,
			};

		case DELETE_BOARD_FAILED:
			return {
				...state,
				isDeleteInProgress: false,
			};

		case ACTIVE_SPRINTS_RETRIEVED:
			return {
				...state,
				sprintInformation: { sprints: action.payload },
			};

		case GET_ACTIVE_SPRINTS_FAILED:
			return {
				...state,
				sprintInformation: { error: true },
			};

		case GET_ACTIVE_SPRINTS:
		case BOARD_DELETED:
			return state;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

import React from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/standard-button';
import ModalDialog, {
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { ViewTracker } from '@atlassian/jira-analytics-web-react/src';
import type { PublicBoard } from '@atlassian/jira-boards-common/src/model';
import { ShortcutScope } from '@atlassian/jira-common-components-keyboard-shortcuts';
import { type IntlShapeV2 as IntlShape, injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	board: PublicBoard;
	copyInProgress: boolean;
	onCancel: () => void;
	onCopy: () => void;
	intl: IntlShape;
};

function CopyBoardsModal(props: Props) {
	const { board, copyInProgress, onCancel, onCopy } = props;
	const {
		intl: { formatMessage },
	} = props;
	const onCancelOrNoop = !copyInProgress ? onCancel : noop;

	return (
		<ShortcutScope>
			<ModalDialog onClose={onCancelOrNoop}>
				<ModalHeader>
					<ModalTitle appearance="warning">
						{formatMessage(messages.heading, { boardName: board.name })}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<p>
						{formatMessage(messages.settingsAndFilterInfoMessage, {
							boardName: board.name,
						})}
					</p>
					<p>{formatMessage(messages.ownerMessage)}</p>
					<ViewTracker />
				</ModalBody>
				<ModalFooter>
					<Button appearance="warning" onClick={onCopy} isDisabled={copyInProgress}>
						{formatMessage(messages.copyButtonCaption)}
					</Button>
					<Button appearance="subtle-link" onClick={onCancelOrNoop} isDisabled={copyInProgress}>
						{formatMessage(messages.cancelButtonCaption)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}

CopyBoardsModal.defaultProps = {
	copyInProgress: false,
	onCancel: noop,
	onCopy: noop,
};

export default injectIntl(CopyBoardsModal);

import { fetchJson$ } from '@atlassian/jira-fetch';

const getEndPointUrl = () => '/rest/greenhopper/1.0/rapidviewconfig/boardLocation';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (_: string, data: string) => {
	const options = {
		method: 'PUT',
		body: JSON.stringify(data),
	};
	return fetchJson$(getEndPointUrl(), options);
};

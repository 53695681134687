import boardNotFound from '@atlassian/jira-boards-common/src/flags/board-not-found';
import {
	type Action,
	DELETED,
	DELETE_FAILED,
	COPIED,
	COPY_FAILED,
	MOVED,
	MOVE_FAILED,
} from '../state/actions/operations';
import {
	getRapidBoardUrlForCopiedBoard,
	getRapidBoardEditUrlForCopiedBoard,
} from '../state/selectors/boards';
import type { State } from '../state/types';
import copyFailed from './copy/failed';
import copied from './copy/success';
import deleteFailed from './delete/failed';
import deleted from './delete/success';
import moveFailed from './move/failed';
import moved from './move/success';

const BOARD_NOT_FOUND_REASON = 'board-not-found';
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action: Action, state: State) => {
	switch (action.type) {
		case DELETED: {
			return deleted(action.payload.board);
		}
		case DELETE_FAILED: {
			if (action.payload.reason === BOARD_NOT_FOUND_REASON) {
				return boardNotFound(action.payload.board);
			}

			return deleteFailed(action.payload.board);
		}
		case COPIED: {
			const { board, copiedBoardSummary } = action.payload;
			return copied(
				board,
				copiedBoardSummary,
				getRapidBoardUrlForCopiedBoard(state, copiedBoardSummary.id, copiedBoardSummary.location),
				getRapidBoardEditUrlForCopiedBoard(
					state,
					copiedBoardSummary.id,
					copiedBoardSummary.location,
				),
			);
		}
		case COPY_FAILED: {
			if (action.payload.reason === BOARD_NOT_FOUND_REASON) {
				return boardNotFound(action.payload.board);
			}

			return copyFailed(action.payload.board);
		}
		case MOVED: {
			return moved(action.payload.board);
		}
		case MOVE_FAILED: {
			return moveFailed(action.payload.board);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return undefined;
		}
	}
};

import { connect } from '@atlassian/jira-react-redux';
import type { Location } from '../../../types/locations';
import type { State } from '../state/model';
import {
	projectsSelector,
	userSelector,
	isPendingSelector,
	recentProjectsLimitSelector,
} from '../state/selectors';
import LocationPicker from './location-picker';

type StateProps = {
	isPending: boolean;
	projects: Location[];
	user: Location;
	recentProjectsLimit: number;
};

const mapStateToProps = (state: State): StateProps => ({
	isPending: isPendingSelector(state),
	projects: projectsSelector(state),
	user: userSelector(state),
	recentProjectsLimit: recentProjectsLimitSelector(state),
});

export default connect(mapStateToProps, {})(LocationPicker);

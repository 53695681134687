import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'directories.boards-v2.copy-modal.heading',
		defaultMessage: 'Copy {boardName}',
		description: 'Copy board modal heading.',
	},
	settingsAndFilterInfoMessage: {
		id: 'directories.boards-v2.copy-modal.settings-and-filter-info-message',
		defaultMessage: 'Your copied board will use the same settings and filter as {boardName}.',
		description:
			'Copy board modal body. It describes what happens when you copy a board, i.e. we replicate both settings and filter of the original board.',
	},
	ownerMessage: {
		id: 'directories.boards-v2.copy-modal.owner-message',
		defaultMessage: 'As the owner, you can change these later.',
		description:
			'Copy board modal additional info. It appears after the body to advise the user that as the owner of the copied board, the user will be able to change the settings of the board after it has been copied.',
	},
	copyButtonCaption: {
		id: 'directories.boards-v2.copy-modal.copy-button-caption',
		defaultMessage: 'Copy',
		description:
			'Label for the copy action button that is located at the footer of the copy modal. Clicking this button will start the copy operation.',
	},
	cancelButtonCaption: {
		id: 'directories.boards-v2.copy-modal.cancel-button-caption',
		defaultMessage: 'Cancel',
		description:
			'Label for the cancel action button that is located at the footer of the copy modal. Clicking this button will abandon the copy operation.',
	},
});

import React, { type ComponentType } from 'react';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product';
import Boards from './view';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Updater: ComponentType<Record<any, any>> = () => <SubProductUpdater subProduct="platform" />;

export default withInjectedProps(Boards, {
	Updater,
});

import flow from 'lodash/flow';
import withProps from '@atlassian/jira-common-with-props';
import CreateDialogLoader from '@atlassian/jira-create-boards-button/src/view/create-dialog-loader';
import CreateButton from '@atlassian/jira-create-boards-button/src/view/stateless-button';
import { injectIntl } from '@atlassian/jira-intl';
import Empty from './view';

export default flow(
	injectIntl,
	withProps({
		CreateDialogLoader,
		CreateButton,
	}),
)(Empty);

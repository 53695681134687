import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	lozenge: {
		id: 'directories.boards-v2.main.content.cells.title.private.lozenge',
		defaultMessage: 'Private',
		description:
			'Displayed in a lozenge under the Name column for private boards as we do not have access to the name of a private board.',
	},
	tooltip: {
		id: 'directories.boards-v2.main.content.cells.title.private.tooltip',
		defaultMessage: "You don't have permission to view this board",
		description:
			'Tooltip message for the lozenge to explain why the lozenge is shown instead the name of the board.',
	},
});

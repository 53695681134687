import { createContext } from 'react';
import { connect as connectWrapper, createProvider } from '@atlassian/jira-react-redux';

const STORE_KEY = 'project-directories.directory.story';

// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type
export const DirectoryContext = createContext<ReactReduxContextValue>(null);

export const storeOptions = {
	storeKey: STORE_KEY,
	context: DirectoryContext,
} as const;

export const Provider = createProvider(DirectoryContext);

export const connect = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapStateToProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapDispatchToProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mergeProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: any = Object.freeze({}),
) =>
	connectWrapper(mapStateToProps, mapDispatchToProps, mergeProps, {
		...options,
		context: DirectoryContext,
		storeKey: STORE_KEY,
	});

import React, { Component } from 'react';
import { styled } from '@compiled/react';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import Avatar from '@atlaskit/avatar';
import { Field, HelperMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import isSubstringFound from '@atlassian/jira-common-util-is-substring-found';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import type { Location, Item } from '../../../../types/locations';
import messages from './messages';

type Props = {
	label: string;
	projects: Location[];
	user: Location;
	placeholder: string;
	recentProjectsLimit: number;
	isPending: boolean;
	onUserSelected: (user: Location) => void;
	onProjectSelected: (project: Location) => void;
	onItemUnselected?: () => void;
};

export type PropsWithIntl = Props & {
	intl: IntlShape;
};

type State = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedItem: Record<any, any> | Item;
	filterValue: string;
};

const key = Symbol('item-context');
const ITEM_LIMIT = 30;

const MENU_CONTEXT = 'menu';

const filterProjects = (projects: Array<Location>, value: string) =>
	projects.filter((project) => isSubstringFound(project.displayName, value));

const projectsToItems = (projects: Array<Location>) =>
	projects.map((project) => ({
		[key]: 'project',
		value: project.id,
		elemBefore: <Avatar size="small" appearance="square" src={project.avatar} />,
		content: project.displayName,
	}));

// @ts-expect-error - TS7031 - Binding element 'context' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatOptionLabel = (option: any, { context }) => {
	if (context === MENU_CONTEXT) {
		return (
			<OptionContainer>
				<Avatar
					size="small"
					appearance={option.type === 'project' ? 'square' : 'circle'}
					src={option.avatar}
				/>
				<OptionLabel>{option.label}</OptionLabel>
			</OptionContainer>
		);
	}
	return option.label;
};

// eslint-disable-next-line jira/react/no-class-components
class LocationPicker extends Component<PropsWithIntl, State> {
	state: State = {
		selectedItem: {},
		filterValue: '',
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange = (item: any) => {
		this.setState({
			selectedItem: item,
		});

		if (item.type === 'user') {
			this.props.onUserSelected(this.props.user);
		} else {
			const projectLocation = this.props.projects.find((project) => project.id === item.value);
			if (projectLocation) {
				this.props.onProjectSelected(projectLocation);
			}
		}
	};

	getProjectsGroup() {
		const { projects } = this.props;
		const { filterValue } = this.state;

		const items = projectsToItems(filterProjects(projects, filterValue));

		return {
			heading: this.props.intl.formatMessage(
				this.shouldLimitItems() ? messages.recentProjectsSelectGroup : messages.projectsSelectGroup,
			),
			items: items.slice(
				0,
				this.shouldLimitItems() ? Math.min(this.props.recentProjectsLimit, ITEM_LIMIT) : ITEM_LIMIT,
			),
		};
	}

	getUserGroup() {
		const { id, displayName, avatar } = this.props.user;

		return {
			heading: this.props.intl.formatMessage(messages.userSelectGroup),
			items: [
				{
					[key]: 'user',
					value: id,
					elemBefore: <Avatar size="small" appearance="circle" src={avatar} />,
					content: displayName,
				},
			],
		};
	}

	getProjectOptions() {
		const { projects } = this.props;
		const { filterValue } = this.state;
		const options = filterProjects(projects, filterValue).map((project) => ({
			label: project.displayName,
			value: project.id,
			avatar: project.avatar,
			type: 'project',
		}));

		return {
			label: this.props.intl.formatMessage(
				this.shouldLimitItems() ? messages.recentProjectsSelectGroup : messages.projectsSelectGroup,
			),
			options: options.slice(
				0,
				this.shouldLimitItems() ? Math.min(this.props.recentProjectsLimit, ITEM_LIMIT) : ITEM_LIMIT,
			),
		};
	}

	getUserOptions() {
		const { id, displayName, avatar } = this.props.user;

		return {
			label: this.props.intl.formatMessage(messages.userSelectGroup),
			options: [
				{
					label: displayName,
					value: id,
					avatar,
					type: 'user',
				},
			],
		};
	}

	getOptions() {
		const { user } = this.props;
		return user && isSubstringFound(user.displayName, this.state.filterValue)
			? [this.getProjectOptions(), this.getUserOptions()]
			: [this.getProjectOptions()];
	}

	getItems() {
		const { user } = this.props;
		return user && isSubstringFound(user.displayName, this.state.filterValue)
			? [this.getProjectsGroup(), this.getUserGroup()]
			: [this.getProjectsGroup()];
	}

	shouldLimitItems() {
		return isEmpty(this.state.filterValue) && Boolean(this.props.recentProjectsLimit);
	}

	handleOnFilterChange = (value: string) => {
		if (!value || value === '' || !this.anyItemContentMatch(value)) {
			if (!isEmpty(this.state.selectedItem)) {
				this.props.onItemUnselected && this.props.onItemUnselected();
				this.setState({ selectedItem: {} });
			}
		}
		this.setState({ filterValue: value });
	};

	anyItemContentMatch(textToMatch: string) {
		return Boolean(flatMap(this.getItems(), 'items').find((item) => item.content === textToMatch));
	}

	render() {
		const { isPending, label, placeholder } = this.props;

		return (
			<>
				<FieldWidthControl>
					<Field
						name={label}
						label={label}
						isRequired={false}
						testId="board-location-dialogs-common.apps.location-picker.location-picker.location-picker"
					>
						{() => (
							<Select
								required
								autoFocus
								isDisabled={isPending}
								placeholder={placeholder}
								options={this.getOptions()}
								formatOptionLabel={formatOptionLabel}
								onChange={this.onChange}
								onInputChange={this.handleOnFilterChange}
								menuPortalTarget={document.body}
								styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
							/>
						)}
					</Field>
				</FieldWidthControl>

				<HelperMessage>
					{this.props.intl.formatMessage(messages.userSelectHelperText)}
				</HelperMessage>
			</>
		);
	}
}

export default injectIntl(LocationPicker);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionLabel = styled.span({
	paddingLeft: token('space.100', '8px'),
	paddingBottom: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWidthControl = styled.div({
	'@media (min-width: 640px)': {
		maxWidth: '50%',
	},
});

import React, { type ComponentType } from 'react';
import type { EntityOperation } from '../../model';

type Props<TOperation, TChildEntityId> = {
	isCreateModalShown: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	CreateModal: ComponentType<Record<any, any>>;
	operations: EntityOperation<TOperation, TChildEntityId>[];
	OperationModal: ComponentType<EntityOperation<TOperation, TChildEntityId>>;
};

export default function Modals<TOperation, TChildEntityId>(
	props: Props<TOperation, TChildEntityId>,
) {
	const { isCreateModalShown, CreateModal, operations, OperationModal } = props;
	return (
		<div>
			{isCreateModalShown && <CreateModal />}
			{operations.map(({ id, operation, childEntityId, isInitializing }) => (
				<OperationModal
					key={id}
					id={id}
					operation={operation}
					childEntityId={childEntityId}
					isInitializing={isInitializing}
				/>
			))}
		</div>
	);
}

import type { PageRequest } from '@atlassian/jira-shared-types';
import type { Filter } from '../../../model';
import get from '../../../rest/boards/get';
import type { Response as RestResponse } from '../../../rest/boards/get/types';
import { transformBoards } from './transformers';
import type { Response as ResponseType } from './types';

export type Response = ResponseType;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (baseUrl: string, request: PageRequest, filter: Filter): Promise<Response> => {
	const restResponse: RestResponse = await get(baseUrl, request, filter, true);
	return transformBoards(baseUrl, restResponse.values, restResponse.total);
};

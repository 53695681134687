import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { predictPageToRequestAfterItemDeletion } from '@atlassian/jira-common-pagination';
import { DELETE } from '../../model/operation-types';
import { ENTITY_UPDATED, type EntityUpdatedAction } from '../../state/actions/operation';
import {
	getTotalItems,
	getItemsPerPage,
	getPage,
	getOnReload,
	getOnPatch,
} from '../../state/selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<any>) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$.ofType(ENTITY_UPDATED).mergeMap((action: EntityUpdatedAction<any, any>) => {
		const { id, operation, patch } = action.payload;

		const state = store.getState();
		const currentPage = getPage(state);

		if (patch) {
			getOnPatch(state)({ id, patch }); // change page's state with a patch instead of reloading a table's page
			return Observable.empty<never>();
		}

		if (operation === DELETE) {
			const itemsPerPage = getItemsPerPage(state);
			const totalItems = getTotalItems(state);

			const predictedPage = predictPageToRequestAfterItemDeletion(
				itemsPerPage,
				totalItems,
				currentPage,
			);

			getOnReload(state)({ page: predictedPage });
		} else {
			getOnReload(state)({ page: currentPage });
		}

		return Observable.empty<never>();
	});

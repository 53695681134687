import * as actions from '../action-types';

export const initDialog = () => ({
	type: actions.INIT_DIALOG,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectLocation = (payload: any) => ({
	type: actions.SELECT_LOCATION,
	payload,
});

export const unselectLocation = () => ({
	type: actions.UNSELECT_LOCATION,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setContext = (payload: any) => ({
	type: actions.SET_CONTEXT,
	payload,
});

export const fetchDataRequest = () => ({
	type: actions.FETCH_DATA_REQUEST,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchLocationsDataSuccess = (payload: any) => ({
	type: actions.FETCH_LOCATIONS_DATA_SUCCESS,
	payload,
});

export const fetchDataFailure = () => ({
	type: actions.FETCH_DATA_FAILURE,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setNewLocation = (payload: any, analyticsEvent: any) => ({
	type: actions.SET_NEW_LOCATION,
	payload,
	meta: {
		analytics: analyticsEvent,
	},
});

export const setLocationSuccess = () => ({
	type: actions.SET_LOCATION_SUCCESS,
});

export const setLocationFailure = () => ({
	type: actions.SET_LOCATION_FAILURE,
});

export const cancelDialog = () => ({
	type: actions.CANCEL_DIALOG,
});

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { ENTITY_CREATED } from '../../state/actions/create';
import { getPage, getOnReload } from '../../state/selectors';

// flow can't deal with the generics here
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<any>) =>
	action$.ofType(ENTITY_CREATED).mergeMap(() => {
		const state = store.getState();
		const page = getPage(state);
		getOnReload(state)({ page });

		return Observable.empty<never>();
	});

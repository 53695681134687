import React, { type ReactNode, type PointerEvent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';

type Props = {
	caption: ReactNode;
	href: string;
	onClick: (event: PointerEvent, analyticsEvent: AnalyticsEvent) => void;
};

export default function LinkCell({ caption, href, onClick, ...props }: Props) {
	return (
		<Container {...props}>
			{href ? (
				<Link href={href} onClick={onClick}>
					{caption}
				</Link>
			) : (
				caption
			)}
		</Container>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'row',
	width: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
});

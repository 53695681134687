import React from 'react';
import InlineMessage from '@atlaskit/inline-message';
import {
	defineMessages,
	injectIntlV2 as injectIntl,
	type IntlShapeV2 as IntlShape,
} from '@atlassian/jira-intl';

type Props = {
	intl?: IntlShape;
};

const i18n = defineMessages({
	title: {
		id: 'locationless-dialog-v2.error-message-secondary-text',
		defaultMessage: 'Something went wrong setting the location',
		description: '',
	},
	message: {
		id: 'locationless-dialog-v2.error-message-text',
		defaultMessage: 'You can try again or get help ',
		description: '',
	},
	here: {
		id: 'locationless-dialog-v2.error-message-here-link-text',
		defaultMessage: 'here',
		description: '',
	},
});

function ErrorMessage(props: Props) {
	const { intl } = props;

	if (!intl) {
		return null;
	}

	return (
		<InlineMessage title={intl.formatMessage(i18n.title)} appearance="error">
			{intl.formatMessage(i18n.message)}
			<a href="https://support.atlassian.com/contact/" rel="noopener noreferrer" target="_blank">
				{intl.formatMessage(i18n.here)}
			</a>
			.
		</InlineMessage>
	);
}

ErrorMessage.defaultProps = {
	intl: undefined,
};

export default injectIntl(ErrorMessage);

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import getBoards from '../../services/boards/get';
import { RETRIEVE, retrieved, retrieveFailed } from '../../state/actions/boards';
import { getBaseUrl } from '../../state/selectors/app';
import { getFilter } from '../../state/selectors/query';
import type { State, Action } from '../../state/types';
import { getServiceRequest } from './selectors';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) => {
	const getDashboardsRequestAction$ = action$.ofType(RETRIEVE);

	return getDashboardsRequestAction$.mergeMap(() => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const request = getServiceRequest(state);
		const filter = getFilter(state);

		return Observable.fromPromise(getBoards(baseUrl, request, filter))
			.mergeMap((result) => Observable.of(retrieved(result)))
			.takeUntil(getDashboardsRequestAction$)
			.catch((error) => {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'retrieveBoards',
						packageName: 'jiraBoardsMain',
						teamName: 'jira-cosmos',
					},
				});
				return Observable.of(retrieveFailed());
			});
	});
};

import type { ReactElement } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../../model';
import { isPrivate } from '../../../../../state/selectors/boards';
import type { State } from '../../../../../state/types';
import PublicVsPrivateSwitchCell from './view';

export type Props = {
	children: (arg1: { isPrivate: boolean }) => ReactElement;
} & EntityCellProps;

const mapStateToProps = () => (state: State, ownProps: Props) => ({
	isPrivate: isPrivate(state, ownProps.id),
});

export default connect(mapStateToProps, {})(PublicVsPrivateSwitchCell);

/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import FavouriteButtonStateless, {
	type Props,
} from '@atlassian/jira-favourite-button-stateless/src';

const styles = cssMap({
	root: {
		display: 'flex',
		justifyContent: 'center',
		padding: token('space.050'),
	},
});

export default function FavouriteCell(props: Props) {
	return (
		<div css={styles.root}>
			<FavouriteButtonStateless {...props} />
		</div>
	);
}

FavouriteCell.defaultProps = {
	isFavourite: undefined,
};

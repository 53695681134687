import React, { Component } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import { MODAL } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './model';
import rootEpic from './ops';
import createStore from './state';
import { setAppProps, getActiveSprints } from './state/actions';
import type { State } from './state/types';
import View from './view';

// eslint-disable-next-line jira/react/no-class-components
class DeleteModal extends Component<Props> {
	static defaultProps = {
		onInitialised: noop,
		onBoardDeleted: noop,
		onCancel: noop,
	};

	constructor(props: Props) {
		super(props);

		this.store = createStore(rootEpic);
		this.store.dispatch(setAppProps(this.props));
		this.store.dispatch(getActiveSprints());
	}

	store: Store<State>;

	render() {
		return (
			<AppBase store={this.store}>
				<AppStyle>
					<View />
				</AppStyle>
			</AppBase>
		);
	}
}

export default AnalyticsSource('deleteBoard', MODAL)(DeleteModal);

import React, { type ReactNode, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type Props = {
	primaryButton: ReactElement;
	secondaryButton: ReactElement;
	spinner: ReactNode;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	marginRight: token('space.200', '16px'),
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	marginBottom: token('space.100', '8px'),
	marginTop: token('space.100', '8px'),
});

const cancelButtonWrapperStyles = xcss({
	marginRight: 'space.050',
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ButtonWrapper>
		<SpinnerWrapper>{props.spinner}</SpinnerWrapper>
		<Box xcss={cancelButtonWrapperStyles}>{props.secondaryButton}</Box>
		{props.primaryButton}
	</ButtonWrapper>
);

import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	height: '100%',
	left: 0,
	position: 'fixed',
	top: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.blanket,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpinnerWrapper = styled.div({
	position: 'fixed',

	left: '50%',
	marginTop: token('space.800', '60px'),
});

import withProps from '@atlassian/jira-common-with-props';
import CreateDialogLoader from './create-dialog-loader';
import StatelessButton from './stateless-button';
import CreateBoardsButton from './view';

export default withProps({
	CreateDialogLoader,
	StatelessButton,
	// @ts-expect-error - TS2345 - Argument of type 'FC<Props>' is not assignable to parameter of type 'AbstractComponent<{ CreateDialogLoader: AbstractComponent<Partial<{ showCreateBoardDialog: () => Promise<void>; }>, unknown>; StatelessButton: any; }, unknown>'.
})(CreateBoardsButton);

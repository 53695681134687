import { combineReducers } from 'redux';
import app from './app';
import boards from './boards';
import query from './query';

export default combineReducers({
	app,
	boards,
	query,
});

import React from 'react';
import { showFlag, dismissFlag } from '../../services';
import type { State } from '../../services/store';
import type { Action, FlagsMapper, Store } from '../../services/types';
import { Dispatcher } from './main';

export type FlagsDispatcherProps<TAction extends Action, TState> = {
	store?: Store<TState>;
	mapper: FlagsMapper<TAction, TState>;
};

export { Dispatcher };

export const FlagsDispatcher = <TAction extends Action, TState extends State>({
	store,
	mapper,
}: FlagsDispatcherProps<TAction, TState>) => (
	<Dispatcher store={store} mapper={mapper} showFlag={showFlag} dismissFlag={dismissFlag} />
);

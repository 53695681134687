import type { PublicBoard } from '@atlassian/jira-boards-common/src/model';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'directories.boards-v2.main.flags.copy.failed.title',
		defaultMessage: "We couldn't copy {boardName}",
		description: 'Title of the flag that gets shown when a board failed to be copied.',
	},
	description: {
		id: 'directories.boards-v2.main.flags.copy.failed.description',
		defaultMessage: 'Something went wrong. Try again.',
		description: 'Body of the flag that gets shown when a board failed to be copied.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (board: PublicBoard) => ({
	type: 'error',
	title: [messages.title, { boardName: board.name }],
	description: messages.description,
});

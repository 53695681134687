import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const contextSelector = (state: any) => state.context;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectedLocationSelector = (state: any) => state.selectedLocation;

/** @deprecated baseUrl in cloud is an empty string. remove code that checks or uses it. */
export const baseUrlSelector = (_: unknown): string => '';

export const rapidViewIdSelector = createSelector(
	contextSelector,
	(context) => context.rapidViewId,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPendingSelector = (state: any) => isEmpty(state.locations);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const locationsSelector = (state: any) => state.locations;

export const selectedLocationIdSelector = createSelector(
	selectedLocationSelector,
	(selectedLocation) => selectedLocation?.location.id,
);

export const selectedLocationTypeSelector = createSelector(
	selectedLocationSelector,
	(selectedLocation) => selectedLocation?.type,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorOnLocationSetSelector = (state: any) => state.errorOnLocationSet;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorOnFetchDataSelector = (state: any) => state.errorOnFetchData;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const recentProjectsSelector = (state: any) => state.recentProjects;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLocationSetSelector = (state: any) => state.isLocationSet;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSettingLocationSelector = (state: any) => state.isSettingLocation;

import React from 'react';
import type { EntityCellProps } from '../../../../model';
import PublicVsPrivateSwitchCell from '../common/public-vs-private-switch-cell';
import PrivateCell from './private';
import PublicCell from './public';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: EntityCellProps) => (
	<PublicVsPrivateSwitchCell {...props}>
		{({ isPrivate }) => (isPrivate ? <PrivateCell /> : <PublicCell {...props} />)}
	</PublicVsPrivateSwitchCell>
);

import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import {
	injectIntl,
	type IntlShape,
	type MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl';
import imageUrl from '../../../../assets/generic-no-results.svg';

/*
 * The react-redux `connect` function is passing in a `this.props.dispatch` function but this component does not use it.
 * It is not a problem but it must be either defined in this Props type, or the type must be inexact.
 */
type Props = {
	message: MessageDescriptor;
	intl: IntlShape;
};

function DefaultEmptyView(props: Props) {
	const { message } = props;
	const {
		intl: { formatMessage },
	} = props;
	return (
		<div data-test-id="global-pages.directories.directory-base.content.table.default-empty-view.empty-state">
			<EmptyState imageUrl={imageUrl} header={formatMessage(message)} size="narrow" />
		</div>
	);
}

export default injectIntl(DefaultEmptyView);

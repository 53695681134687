import React, { Component } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import { ReportErrors } from '@atlassian/jira-errors-handling';
import { MODAL } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './model';
import rootEpic from './ops';
import createStore from './state';
import { setAppProps } from './state/actions';
import type { State } from './state/types';
import View from './view';

// eslint-disable-next-line jira/react/no-class-components
class CopyModal extends Component<Props> {
	static defaultProps = {
		onInitialised: noop,
		onBoardCopied: noop,
		onCancel: noop,
	};

	constructor(props: Props) {
		super(props);

		this.props.onInitialised();

		this.store = createStore(rootEpic);
		this.store.dispatch(setAppProps(this.props));
	}

	store: Store<State>;

	render() {
		return (
			<AppBase store={this.store} hasErrorBoundary={false}>
				<ReportErrors id="unhandled" packageName="jiraCopyBoardsModal" teamName="jira-cosmos">
					<AppStyle>
						<View />
					</AppStyle>
				</ReportErrors>
			</AppBase>
		);
	}
}

export default AnalyticsSource('copyBoard', MODAL)(CopyModal);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectsSelectGroup: {
		id: 'board-location-dialogs.common.location-picker.projects-select-group',
		defaultMessage: 'Projects',
		description: 'Select dropdown group heading for all project locations.',
	},
	recentProjectsSelectGroup: {
		id: 'board-location-dialogs.common.location-picker.recent-projects-select-group',
		defaultMessage: 'Recent projects',
		description: 'Select dropdown group heading for all recent project locations.',
	},
	userSelectGroup: {
		id: 'board-location-dialogs.common.location-picker.user-select-group',
		defaultMessage: 'Your profile',
		description: 'Select dropdown group heading for the current user location.',
	},
	userSelectHelperText: {
		id: 'board-location-dialogs.common.location-picker.user-select-group-input-helper',
		defaultMessage: 'The board’s URL will change to match this location.',
		description: 'Helper text for the Select dropdown',
	},
});

/** @jsx jsx */
import React, { Component } from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import type { MoveSingleBoardDialogAppType as MoveSingleBoardDialogType } from '@atlassian/jira-board-location-dialogs-move-single/src';
import type { Board } from '@atlassian/jira-boards-common/src/model';

type Props = {
	baseUrl: string;
	board: Board;
	MoveSingleBoardDialog: MoveSingleBoardDialogType;
	onCancel: () => void;
	onBoardMoved: (board: Board) => void;
	onBoardMoveFailed: (board: Board) => void;
	onInitialDataFetchFailed: (board: Board) => void;
	onInitialDataFetched: () => void;
};

type State = {
	showDialog: boolean;
};

const styles = cssMap({
	show: {
		display: 'inherit',
	},
	hide: {
		display: 'none',
	},
});

// eslint-disable-next-line jira/react/no-class-components
export default class MoveModal extends Component<Props, State> {
	state = {
		showDialog: false,
	};

	onLocationSet = (): void => {
		this.props.onBoardMoved(this.props.board);
	};

	onSetLocationError = (): void => {
		this.props.onBoardMoveFailed(this.props.board);
	};

	onFetchDataError = (): void => {
		this.props.onInitialDataFetchFailed(this.props.board);
	};

	onFetchDataSuccess = (): void => {
		this.setState({
			showDialog: true,
		});
		this.props.onInitialDataFetched();
	};

	render() {
		const { MoveSingleBoardDialog, baseUrl, board, onCancel } = this.props;
		const { showDialog } = this.state;

		const rapidViewId = parseInt(board.id, 10);
		const currentLocation = board.location
			? { avatar: board.location.avatarURI, displayName: board.location.displayName }
			: {};
		// @ts-expect-error - TS2339 - Property 'isPrivate' does not exist on type 'Board'. | TS2339 - Property 'name' does not exist on type 'Board'.
		const boardName = board.isPrivate ? undefined : board.name;

		const dialog = (
			<MoveSingleBoardDialog
				baseUrl={baseUrl}
				rapidViewId={rapidViewId}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				currentLocation={currentLocation as any}
				onLocationSet={this.onLocationSet}
				onCancel={onCancel}
				onSetLocationError={this.onSetLocationError}
				onFetchDataError={this.onFetchDataError}
				onFetchDataSuccess={this.onFetchDataSuccess}
				showErrorMessage={false}
				boardName={boardName}
			/>
		);

		if (showDialog) {
			return <div css={styles.show}>{dialog}</div>;
		}
		return <div css={styles.hide}>{dialog}</div>;
	}
}

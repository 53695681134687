import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	name: {
		id: 'directories.boards-v2.main.name',
		defaultMessage: 'Name',
		description: 'Caption for the column heading for displaying the board name',
	},
	type: {
		id: 'directories.boards-v2.main.type',
		defaultMessage: 'Type',
		description: 'Caption for the column heading for displaying the board type',
	},
	administrators: {
		id: 'directories.boards-v2.main.administrators',
		defaultMessage: 'Admin',
		description: "Caption for the column heading for displaying the board's administrators",
	},
	location: {
		id: 'directories.boards-v2.main.location',
		defaultMessage: 'Location',
		description: "Caption for the column heading for displaying the board's location",
	},
	emptyFilterResultMessage: {
		id: 'directories.boards-v2.main.empty-filter-result-message',
		defaultMessage: 'No boards were found that match your search',
		description:
			"Message to be displayed when user tried to filter boards but the filter criteria didn't match any",
	},
	tableAriaLabel: {
		id: 'directories.boards-v2.main.table-aria-label',
		defaultMessage: 'Boards details',
		description:
			'Message to be used as aria-label of boards table. The aria-label is announced by screen readers and helps users who use them understand the purpose of table',
	},
	filterResultsAnnouncement: {
		id: 'directories.boards-v2.main.filter-results-announcement',
		defaultMessage:
			'{itemCount, plural, =0 {No boards were found that match your search} one {{itemCount} board found} other {{itemCount} boards found}}',
		description:
			'Message for screen reader users to announce how many boards were found after filtering',
	},
});

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src';
import putNewLocation$ from '../../../../../requests/put-new-location';
import { setLocationSuccess, setLocationFailure } from '../../action-creators';
import { SET_NEW_LOCATION } from '../../action-types';
import {
	baseUrlSelector,
	selectedLocationIdSelector,
	selectedLocationTypeSelector,
	rapidViewIdSelector,
} from '../../selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: any, store: any) =>
	// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
	action$.ofType(SET_NEW_LOCATION).switchMap((action) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const selectedlocationType = selectedLocationTypeSelector(state);
		const selectedLocationId = selectedLocationIdSelector(state);
		const boardId = rapidViewIdSelector(state);
		const data = {
			rapidViewId: boardId,
			locationType: selectedlocationType,
		};

		if (selectedlocationType === 'project') {
			// @ts-expect-error - TS2339 - Property 'locationId' does not exist on type '{ rapidViewId: any; locationType: any; }'.
			data.locationId = selectedLocationId;
		}

		return (
			// @ts-expect-error - TS2345 - Argument of type '{ rapidViewId: any; locationType: any; }' is not assignable to parameter of type 'string'.
			putNewLocation$(baseUrl, data)
				.map(() => {
					// @ts-expect-error - TS2345 - Argument of type '{ objectId: any; objectType: string; containerType: any; containerId: any; actionSubjectId: any; attributes: { boardId: any; updatedItems: { name: string; newValId: any; newValName: any; }[]; }; }' is not assignable to parameter of type 'Readonly<Readonly<{ name?: string | undefined; containerId?: string | undefined; containers?: Containers | undefined; containerType?: string | undefined; objectType?: string | undefined; objectId?: string | undefined; readonly tags?: readonly string[] | undefined; attributes?: Readonly<...> | undefined; }> & { ...; }>'.
					fireTrackAnalytics(action.meta.analytics, {
						objectId: boardId,
						objectType: 'board',
						containerType: selectedlocationType,
						containerId: selectedLocationId,
						actionSubjectId: boardId,
						attributes: {
							boardId,
							updatedItems: [
								{
									name: 'boardLocation',
									newValId: selectedLocationId,
									newValName: selectedlocationType,
								},
							],
						},
					});
					return setLocationSuccess();
				})
				// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
				.catch((anotherError) => Observable.of(setLocationFailure(anotherError)))
		);
	});

import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Field } from '@atlaskit/form';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';
import { AvatarItem, LocationName, FieldWrapper } from './styled';

type Props = {
	avatarUrl: string;
	locationName: string;
};

type PropsWithIntl = Props & {
	intl: IntlShape;
};

function CurrentLocation(props: PropsWithIntl) {
	const {
		avatarUrl,
		locationName,
		intl: { formatMessage },
	} = props;

	const label = formatMessage(messages.label);

	return (
		<FieldWrapper>
			<Field name={label} label={label} isRequired={false}>
				{() => (
					<AvatarItem>
						<Avatar size="small" appearance="square" src={avatarUrl} />
						<LocationName>{locationName}</LocationName>
					</AvatarItem>
				)}
			</Field>
		</FieldWrapper>
	);
}

export default injectIntl(CurrentLocation);

import { freeze } from 'icepick';
import { SET_CONTEXT } from './context-action-type';
import type { ContextType } from './context-type';

const initialState: ContextType = freeze({
	baseUrl: undefined,
	rapidViewId: undefined,
	currentLocation: undefined,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state: ContextType = initialState, action: any) => {
	const { type, payload } = action;

	switch (type) {
		case SET_CONTEXT:
			return freeze(payload);
		default:
			return state;
	}
};

import { assocIn } from 'icepick';
import type { Action } from '../action-creators';
import * as actions from '../action-types';
import { defaultState as initialState } from '../default-state';
import type { State } from '../model';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action) => {
	switch (action.type) {
		case actions.FETCH_DATA_SUCCESS:
			return assocIn(state, ['locations'], action.payload);
		case actions.SET_CONTEXT:
			return assocIn(state, ['context'], action.payload);
		case actions.CANCEL_DIALOG:
			return assocIn(state, ['locations'], {});
		default:
			break;
	}

	return state;
};

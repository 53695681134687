import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AvatarItem = (props: ComponentPropsWithoutRef<any>) => (
	<AvatarItemComponent {...props} />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LocationName = (props: ComponentPropsWithoutRef<any>) => (
	<LocationNameComponent {...props} />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldWrapper = (props: ComponentPropsWithoutRef<any>) => (
	<FieldWrapperComponent {...props} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LocationNameComponent = styled.div({
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarItemComponent = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWrapperComponent = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginTop: token('space.250', '20px'),
	},
});

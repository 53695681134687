import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import type { Board } from '@atlassian/jira-boards-common/src/model';
import type { Board as RestBoard } from '@atlassian/jira-boards-common/src/rest/types';
import { transformBoard } from '@atlassian/jira-boards-common/src/services/transformers';
import type { Response } from './types';

export const transformBoards = (baseUrl: string, boards: RestBoard[], total: number): Response => {
	const order = boards.map((i) => `${i.id}`);
	const responsesById: {
		[key: string]: RestBoard;
	} = keyBy(boards, (board) => board.id);
	const page: {
		[key: string]: Board;
	} = mapValues(responsesById, (response: RestBoard) => transformBoard(baseUrl, response));

	return {
		page,
		order,
		total,
	};
};

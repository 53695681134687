import React from 'react';
import type { EntityCellProps } from '../../../../model';
import PublicVsPrivateSwitchCell from '../common/public-vs-private-switch-cell';
import PublicCell from './public';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: EntityCellProps) => (
	<PublicVsPrivateSwitchCell {...props}>
		{/* @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'. */}
		{({ isPrivate }) => (isPrivate ? null : <PublicCell {...props} />)}
	</PublicVsPrivateSwitchCell>
);

import type { State } from './types';

export const getBaseUrl = (state: State) => state.appProps.baseUrl;

export const getBoard = (state: State) => state.appProps.board;

export const getOnBoardCopied = (state: State) => state.appProps.onBoardCopied;
export const getOnBoardCopyFailed = (state: State) => state.appProps.onBoardCopyFailed;
export const getOnCancel = (state: State) => state.appProps.onCancel;

export const isCopyInProgress = (state: State) => state.isCopyInProgress;

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { BOARDS_ERROR_ANALYTICS } from '@atlassian/jira-boards-common/src/common/constant';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error';
import type FetchError from '@atlassian/jira-fetch/src/utils';
import copyBoard from '../../services/copy-board';
import { type Action, COPY_BOARD, copyBoardFailed, boardCopied } from '../../state/actions';
import {
	getBaseUrl,
	getBoard,
	getOnBoardCopied,
	getOnBoardCopyFailed,
} from '../../state/selectors';
import type { State } from '../../state/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(COPY_BOARD).mergeMap(() => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const board = getBoard(state);

		return Observable.fromPromise(copyBoard(baseUrl, board.id))
			.mergeMap((copiedBoardSummary) => {
				getOnBoardCopied(state)(board, copiedBoardSummary);
				return Observable.of(boardCopied());
			})
			.catch((error: FetchError) => {
				logSafeErrorWithoutCustomerData(
					error,
					'directories.boards-v2.copy-modal.ops.copy-board',
					BOARDS_ERROR_ANALYTICS,
				);
				const reason = error.statusCode === 404 ? 'board-not-found' : 'unknown';
				getOnBoardCopyFailed(state)(board, reason);
				return Observable.of(copyBoardFailed({ reason }));
			});
	});

import type { SortDirection } from '@atlassian/jira-common-constants/src/sort-directions';

export type Page<Entity> = {
	maxResults: number;
	startAt: number;
	total: number;
	isLast: boolean;
	values: Entity[];
};

export type PageRequest = {
	startAt: number;
	maxResults: number;
	expand?: string;
	orderBy?: string;
	searchBy?: string;
};

export type OrderDirection = SortDirection;

export type OrderRequest = {
	field: string;
	direction: SortDirection;
};

export type ExpandList = string[];
export type SearchByList = string[];

export const createRangePageRequest = (
	startAt: number,
	maxResults: number,
	order?: OrderRequest,
	expand?: ExpandList,
): PageRequest => {
	const pageRequest: PageRequest = {
		maxResults,
		startAt,
	};
	if (order) {
		const orderString = `${order.direction === 'ASC' ? '+' : '-'}${order.field}`;
		pageRequest.orderBy = orderString;
	}
	if (expand && expand.length) {
		pageRequest.expand = expand.join(',');
	}
	return pageRequest;
};

export const createRangePageRequestWithSearchBy = (
	startAt: number,
	maxResults: number,
	order?: OrderRequest,
	expand?: ExpandList,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	searchBy: SearchByList,
): PageRequest => {
	const pageRequest: PageRequest = {
		maxResults,
		startAt,
	};
	if (order) {
		const orderString = `${order.direction === 'ASC' ? '+' : '-'}${order.field}`;
		pageRequest.orderBy = orderString;
	}
	if (expand && expand.length) {
		pageRequest.expand = expand.join(',');
	}

	if (searchBy && searchBy.length) {
		pageRequest.searchBy = searchBy.join(',');
	}

	return pageRequest;
};

export const createPageRequest = (
	page: number,
	itemsPerPage: number,
	order?: OrderRequest,
	expand?: ExpandList,
): PageRequest => createRangePageRequest(itemsPerPage * (page - 1), itemsPerPage, order, expand);

export const createPageRequestWithSearchBy = (
	page: number,
	itemsPerPage: number,
	order?: OrderRequest,
	expand?: ExpandList,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	searchBy: SearchByList,
): PageRequest =>
	createRangePageRequestWithSearchBy(
		itemsPerPage * (page - 1),
		itemsPerPage,
		order,
		expand,
		searchBy,
	);

import type { Dispatch } from 'redux';
import { MoveSingleBoardDialogApp as MoveSingleBoardDialog } from '@atlassian/jira-board-location-dialogs-move-single/src';
import type { Board } from '@atlassian/jira-boards-common/src/model';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model';
import { connect } from '@atlassian/jira-react-redux';
import { type Action, moved, moveFailed } from '../../state/actions/operations';
import { getBaseUrl } from '../../state/selectors/app';
import { getBoard } from '../../state/selectors/boards';
import type { State } from '../../state/types';
import MoveModal from './view';

const mapStateToProps = (state: State, ownProps: OperationModalProps<Board>) => ({
	baseUrl: getBaseUrl(state),
	board: getBoard(state, ownProps.entityId),
	MoveSingleBoardDialog,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OperationModalProps<Board>) => ({
	onCancel: ownProps.onCancel,
	onBoardMoved: (board: Board) => {
		dispatch(moved(board));
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
		ownProps.onUpdated();
	},
	onBoardMoveFailed: (board: Board) => dispatch(moveFailed(board)),
	onInitialDataFetchFailed: (board: Board) => {
		dispatch(moveFailed(board));
		ownProps.onDataRetrieveFailed();
	},
	onInitialDataFetched: ownProps.onDialogShown,
});

// @ts-expect-error - TS2345 - Argument of type 'typeof MoveModal' is not assignable to parameter of type 'Component<{ baseUrl: string; board: Board; MoveSingleBoardDialog: typeof MoveSingleBoardDialogApp; } & { onCancel: () => void; onBoardMoved: (board: Board) => void; onBoardMoveFailed: (board: Board) => MoveFailedAction; onInitialDataFetchFailed: (board: Board) => void; onInitialDataFetched: () => void; } & Operation...'.
export default connect(mapStateToProps, mapDispatchToProps)(MoveModal);

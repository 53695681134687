import type { Board } from '@atlassian/jira-boards-common/src/model';
import type { OrderedEntityPage } from '@atlassian/jira-directory-base/src/model';

// retrieve

export const RETRIEVE = 'state.actions.boards.RETRIEVE' as const;

export type RetrieveAction = {
	type: typeof RETRIEVE;
};

export const retrieve = (): RetrieveAction => ({
	type: RETRIEVE,
});

// retrieved

export const RETRIEVED = 'state.actions.boards.RETRIEVED' as const;

export type RetrievedPayload = OrderedEntityPage<string, Board>;

export type RetrievedAction = {
	type: typeof RETRIEVED;
	payload: RetrievedPayload;
};

export const retrieved = (payload: RetrievedPayload): RetrievedAction => ({
	type: RETRIEVED,
	payload,
});

// retrieve failed

export const RETRIEVE_FAILED = 'state.actions.boards.RETRIEVE_FAILED' as const;

export type RetrieveFailedAction = {
	type: typeof RETRIEVE_FAILED;
};

export const retrieveFailed = (): RetrieveFailedAction => ({
	type: RETRIEVE_FAILED,
});

// patch

export const PATCH = 'state.actions.boards.PATCH' as const;

export type PatchPayload = {
	id: string;
	patch: Partial<Board>;
};

export type PatchAction = {
	type: typeof PATCH;
	payload: PatchPayload;
};

export const patch = (payload: PatchPayload): PatchAction => ({
	type: PATCH,
	payload,
});

// actions

export type Action = RetrieveAction | RetrievedAction | RetrieveFailedAction | PatchAction;

import React, { type SyntheticEvent, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import range from 'lodash/range';
import Pagination from '@atlaskit/pagination';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

/*
 * The react-redux `connect` function is passing in a `this.props.dispatch` function but this component does not use it.
 * It is not a problem but it must be either defined in this Props type, or the type must be inexact.
 */
type Props = {
	currentPage: number;
	totalPages: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPageChange: (arg1: SyntheticEvent<any>, arg2: number) => void;
	intl: IntlShape;
};

function Paginator(props: Props) {
	const {
		currentPage,
		totalPages,
		intl: { formatMessage },
	} = props;

	const onPageChange = (
		data: MouseEvent<HTMLElement>,
		event: number,
		// @ts-expect-error - TS2304 - Cannot find name 'UIAnalyticsEvent'.
		analyticsEvent: undefined | UIAnalyticsEvent,
	) => {
		props.onPageChange(data, event);
		if (analyticsEvent) {
			fireUIAnalytics(analyticsEvent, 'pagination');
		}
	};

	if (totalPages > 1) {
		return (
			<Container data-test-id="global-pages.directories.directory-base.content.paginator.container">
				<Pagination
					selectedIndex={currentPage - 1}
					// @ts-expect-error - TS2322 - Type '(data: MouseEvent<HTMLElement>, event: number, analyticsEvent: undefined | UIAnalyticsEvent) => void' is not assignable to type '(event: SyntheticEvent<Element, Event>, page: number, analyticsEvent?: UIAnalyticsEvent | undefined) => void'.
					onChange={onPageChange}
					pages={range(1, totalPages + 1)}
					previousLabel={formatMessage(messages.previousButton)}
					nextLabel={formatMessage(messages.nextButton)}
				/>
			</Container>
		);
	}
	return null;
}

export default injectIntl(Paginator);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingBottom: token('space.300', '24px'),
	marginLeft: token('space.negative.150', '-12px'),
});

import { connect } from '@atlassian/jira-react-redux';
import { copyBoard } from '../state/actions';
import { getBoard, isCopyInProgress, getOnCancel } from '../state/selectors';
import type { State } from '../state/types';
import CopyBoardsModal from './view';

const mapStateToProps = (state: State) => ({
	board: getBoard(state),
	copyInProgress: isCopyInProgress(state),
	onCancel: getOnCancel(state),
});

export default connect(mapStateToProps, {
	onCopy: copyBoard,
})(CopyBoardsModal);

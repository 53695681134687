// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import LinkCell from '@atlassian/jira-common-directory-v2/src/components/link-cell';
import { TransitionComponent } from '@atlassian/jira-common-directory-v2/src/components/transition-component';

export type Props = JSX.LibraryManagedAttributes<
	typeof LinkCell,
	ComponentProps<typeof LinkCell>
> & {
	type: string;
};

const Public = ({ caption, href, type, onClick }: Props) => (
	<TransitionComponent href={href} onClick={onClick}>
		{(props) => <LinkCell {...props} caption={caption} data-item-board-type={type} />}
	</TransitionComponent>
);

export default Public;

import React, { type ReactNode, Component, type ReactElement, type SyntheticEvent } from 'react';
import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ModalDialog, {
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import {
	AnalyticsSource,
	AnalyticsSubject,
	ViewTracker,
} from '@atlassian/jira-analytics-web-react';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import { ShortcutScope } from '@atlassian/jira-common-components-keyboard-shortcuts';
import { FormattedMessage } from '@atlassian/jira-intl';
import { MODAL } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import ActionButtons from '../../../components/action-buttons';
import ContentWrapper from '../../../components/content-wrapper';
import LoadingScreen from '../../../components/loading-screen';
import type {
	Locations as LocationsType,
	Location,
	SelectedLocation as SelectedLocationType,
} from '../../../types/locations';
import { LocationPickerApp as LocationPicker } from '../../location-picker';
import messages from '../messages';
import {
	initDialog,
	setNewLocation,
	selectLocation,
	unselectLocation,
	cancelDialog,
} from '../state/action-creators';
import {
	baseUrlSelector,
	locationsSelector,
	errorOnLocationSetSelector,
	errorOnFetchDataSelector,
	recentProjectsSelector,
	isPendingSelector,
	isLocationSetSelector,
	selectedLocationSelector,
	isSettingLocationSelector,
} from '../state/selectors';
import { type ClassicLocationlessData, getClassicLocationlessRedirectUrl } from './utils';

interface DefaultProps {
	initDialog: () => void;
	onCancel: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onConfirm: (arg1?: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLocationSet: (arg1?: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLocationSetError: (arg1?: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFetchDataError: (arg1?: any) => void;
	onFetchDataSuccess: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onProjectSelected: (arg1?: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUserSelected: (arg1?: any) => void;
	errorElement: null | ReactElement;
}

type Props = DefaultProps & {
	initDialog: () => void;
	isOpen: boolean;
	isLocationSet: boolean;
	title: ReactElement;
	baseUrl: string;
	locations: LocationsType;
	recentProjectsLimit: number;
	errorElement: ReactElement;
	errorOnLocationSet: boolean;
	errorOnFetchData: boolean;
	onCancel?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onConfirm: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLocationUnselected: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onLocationSet?: (arg1: SelectedLocationType | any) => void;
	onLocationSetError?: () => void;
	onFetchDataError?: () => void;
	onUserSelected: (arg1: SelectedLocationType) => void;
	onProjectSelected: (arg1: SelectedLocationType) => void;
	isPending: boolean;
	children: ReactNode;
	cancelActionText: string;
	confirmActionText: string;
	locationPickerLabel: string;
	locationPickerPlaceholder: string;
	selectedLocation?: SelectedLocationType;
	isSettingLocation: boolean;
	classicLocationless?: boolean;
	boardId?: number;
};
// eslint-disable-next-line jira/react/no-class-components
export class SingleBoardFrame extends Component<Props> {
	static defaultProps: DefaultProps = {
		initDialog: noop,
		onCancel: noop,
		onConfirm: noop,
		onLocationSet: noop,
		onLocationSetError: noop,
		onFetchDataError: noop,
		onFetchDataSuccess: noop,
		onProjectSelected: noop,
		onUserSelected: noop,
		errorElement: null,
		// @ts-expect-error - TS2322 - Type '{ initDialog: (...args: any[]) => void; onCancel: (...args: any[]) => void; onConfirm: (...args: any[]) => void; onLocationSet: (...args: any[]) => void; onLocationSetError: (...args: any[]) => void; onFetchDataError: (...args: any[]) => void; ... 6 more ...; isSettingLocation: boolean; }' is not assignable to type 'DefaultProps'.
		selectedLocation: null,
		errorOnFetchData: false,
		isSettingLocation: false,
		classicLocationless: false,
		boardId: null,
	};

	componentDidMount() {
		this.props.initDialog();
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.isLocationSet && !this.props.isLocationSet) {
			this.props.onLocationSet(this.props.selectedLocation);
		}

		if (nextProps.errorOnLocationSet && !this.props.errorOnLocationSet) {
			this.props.onLocationSetError();
		}

		if (nextProps.errorOnFetchData && !this.props.errorOnFetchData) {
			this.props.onFetchDataError();
		}

		if (!nextProps.isPending && this.props.isPending) {
			this.props.onFetchDataSuccess();
		}
	}

	handleOnProjectSelected = (location: Location) => {
		const selectedLocation: SelectedLocationType = {
			location,
			type: 'project',
		};

		this.props.onProjectSelected(selectedLocation);
	};

	handleOnUserSelected = (location: Location) => {
		const selectedLocation: SelectedLocationType = {
			location,
			type: 'user',
		};

		this.props.onUserSelected(selectedLocation);
	};

	handleOnItemUnselected = () => {
		this.props.onLocationUnselected();
	};

	handleOnCancel = () => {
		this.props.onCancel();
	};

	renderHeader = () => {
		const { errorElement } = this.props;

		return (
			<ModalTitle appearance="warning">
				<Heading size="medium">{this.props.title}</Heading>
				{errorElement}
			</ModalTitle>
		);
	};

	renderButtons = () => {
		const { onConfirm, confirmActionText, cancelActionText, selectedLocation, isSettingLocation } =
			this.props;

		const spinner = isSettingLocation ? <Spinner size="small" /> : null;

		return (
			<ActionButtons
				spinner={spinner}
				secondaryButton={
					<Button
						appearance="subtle-link"
						onClick={this.handleOnCancel}
						isDisabled={isSettingLocation}
					>
						{cancelActionText}
					</Button>
				}
				primaryButton={
					<Button
						appearance="warning"
						onClick={() =>
							onConfirm(event, {
								classicLocationless: this.props.classicLocationless,
								selectedLocation,
								boardId: this.props.boardId,
							})
						}
						isDisabled={isEmpty(selectedLocation) || isSettingLocation}
					>
						{confirmActionText}
					</Button>
				}
			/>
		);
	};

	render() {
		if (this.props.isPending) {
			return <LoadingScreen />;
		}

		return (
			this.props.isOpen && (
				<ShortcutScope>
					<ModalDialog width="medium" onClose={this.props.onCancel}>
						<ModalHeader>{this.renderHeader()}</ModalHeader>
						<ModalBody>
							<ContentWrapper>
								{this.props.children}
								<LocationPicker
									baseUrl={this.props.baseUrl}
									recentProjectsLimit={this.props.recentProjectsLimit}
									label={this.props.locationPickerLabel}
									placeholder={this.props.locationPickerPlaceholder}
									locations={this.props.locations}
									onProjectSelected={this.handleOnProjectSelected}
									onUserSelected={this.handleOnUserSelected}
									onItemUnselected={this.handleOnItemUnselected}
								/>

								<p>
									<FormattedMessage {...messages.classicLocationlessActionPromptText} />
								</p>
							</ContentWrapper>
							<ViewTracker />
						</ModalBody>
						<ModalFooter>{this.renderButtons()}</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)
		);
	}
}

interface OwnProps {
	onUserSelected: (arg1: SelectedLocationType) => void;
	onProjectSelected?: (arg1: SelectedLocationType) => void;
	onCancel?: () => void;
}

interface StateProps {
	isLocationSet: boolean;
	errorOnLocationSet: boolean;
	selectedLocation: SelectedLocationType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	context: any;
	locations: LocationsType;
	recentProjects: number;
}

const mapStateToProps = (state: StateProps) => ({
	locations: locationsSelector(state),
	baseUrl: baseUrlSelector(state),
	recentProjectsLimit: recentProjectsSelector(state),
	isPending: isPendingSelector(state),
	isLocationSet: isLocationSetSelector(state),
	selectedLocation: selectedLocationSelector(state),
	errorOnLocationSet: errorOnLocationSetSelector(state),
	errorOnFetchData: errorOnFetchDataSelector(state),
	isSettingLocation: isSettingLocationSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
	initDialog: () => {
		dispatch(initDialog());
	},

	onConfirm: (
		event: SyntheticEvent<HTMLElement>,
		classicLocationlessData?: ClassicLocationlessData,
		analyticsEvent?: AnalyticsEvent,
	) => {
		const { classicLocationless } = classicLocationlessData ?? {};

		if (classicLocationless) {
			const redirectUrl =
				classicLocationlessData && getClassicLocationlessRedirectUrl(classicLocationlessData);

			if (redirectUrl) {
				window.location.href = redirectUrl;
			}
		}

		dispatch(setNewLocation({}, analyticsEvent));
	},
	onCancel: () => {
		dispatch(cancelDialog());
		ownProps.onCancel && ownProps.onCancel();
	},
	onProjectSelected: (location: SelectedLocationType) => {
		dispatch(selectLocation(location));
		ownProps.onProjectSelected && ownProps.onProjectSelected(location);
	},
	onUserSelected: (location: SelectedLocationType) => {
		dispatch(selectLocation(location));
		ownProps.onUserSelected && ownProps.onUserSelected(location);
	},
	onLocationUnselected: () => dispatch(unselectLocation()),
});

// @ts-expect-error - TS2345 - Argument of type 'typeof SingleBoardFrame' is not assignable to parameter of type 'Component<never>'.
export const ConnectedAdminDialog = connect(mapStateToProps, mapDispatchToProps)(SingleBoardFrame);

export default flow(
	withAnalyticsEvents({
		onConfirm: { action: 'updated' },
	}),
	connect(mapStateToProps, mapDispatchToProps),
	AnalyticsSource('moveBoard', MODAL),
	AnalyticsSubject('board'),
)(SingleBoardFrame);

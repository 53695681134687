import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createBoard: {
		id: 'directories.boards-v2.create-button.stateless-button.create-board',
		defaultMessage: 'Create board',
		description:
			'Text on the create board button. When button is clicked, the create board dialog is displayed.',
	},
});

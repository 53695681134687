import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { fetchJson$ } from '@atlassian/jira-fetch';
import type { Locations } from '../types/locations';

export type { Locations };

const getEndPointUrl = (numberOfRecentProjects: number) => {
	const sortByRecent = numberOfRecentProjects ? `sortByRecent=${numberOfRecentProjects}` : '';
	return `/rest/greenhopper/1.0/boardLocation.json?${sortByRecent}`;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, numberOfRecentProjects: number): Observable<Locations> =>
	fetchJson$(getEndPointUrl(numberOfRecentProjects)).map((payload) => ({
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		projects: payload.projects,
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		user: payload.user,
	}));

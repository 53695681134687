import React from 'react';

const isEmpty = (s?: string | null): boolean => s === undefined || s === null || s === '';

const getDisplayName = <T,>(x: Flow.AbstractComponent<T, unknown>): string => {
	// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
	if (!isEmpty(x.displayName)) return x.displayName;
	if (!isEmpty(x.name)) return x.name;
	return 'Component';
};

export const withProps =
	<A, B>(
		defaults: A,
	): ((
		arg1: Flow.AbstractComponent<A & B, unknown>,
	) => Flow.AbstractComponent<Partial<A> & B, unknown>) =>
	(
		Component: Flow.AbstractComponent<A & B, unknown>,
	): Flow.AbstractComponent<Partial<A> & B, unknown> => {
		const displayName = getDisplayName<A & B>(Component);
		// @ts-expect-error - TS2698 - Spread types may only be created from object types. | TS2698 - Spread types may only be created from object types.
		const WithProps = (props: Partial<A> & B) => <Component {...{ ...defaults, ...props }} />;
		WithProps.displayName = `WithProps(${displayName})`;
		// @ts-expect-error - TS2322 - Type '{ (props: Partial<A> & B): JSX.Element; displayName: string; }' is not assignable to type 'AbstractComponent<Partial<A> & B, unknown>'.
		return WithProps;
	};

export default withProps;

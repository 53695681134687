import { SET_LOCATION_SUCCESS, SET_LOCATION_FAILURE } from './new-location-action-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLocationFailure = (payload: any) => ({
	type: SET_LOCATION_FAILURE,
	payload,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLocationSuccess = (payload: any, analyticsEvent: any) => ({
	type: SET_LOCATION_SUCCESS,
	payload,
	meta: {
		analytics: analyticsEvent,
	},
});

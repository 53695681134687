import type { Store } from 'redux';
import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate';
import rootReducer from './reducer';
import type { State, Action } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (rootEpic?: Epic<Action, State>, initialState?: State): Store<State> =>
	initiateStore<State, Action, undefined>({
		initialState,
		appName: 'directories/boards-v2',
		rootReducer,
		rootEpic,
	});

import { createSelector } from 'reselect';
import StarTitle from '@atlassian/jira-common-directory-v2/src/components/star-title-cell';
import { COPY, DELETE, MOVE } from '@atlassian/jira-directory-base/src/model/operation-types';
import { NAME } from '../model/sort-field';
import { getLoggedInUserAccountId } from '../state/selectors/app';
import TypeActions from './content/cells/actions';
import AdministratorsCell from './content/cells/administrators';
import FavouriteCell from './content/cells/favourite';
import LocationCell from './content/cells/location';
import TitleCell from './content/cells/title';
import CopyModal from './copy-modal';
import DeleteModal from './delete-modal';
import messages from './messages';
import MoveModal from './move-modal';

export const operationModals = {
	[DELETE]: DeleteModal,
	[COPY]: CopyModal,
	[MOVE]: MoveModal,
} as const;

export const emptyFilterResultConfiguration = {
	message: messages.emptyFilterResultMessage,
} as const;

export const getTableConfiguration = createSelector(
	getLoggedInUserAccountId,
	(loggedInUserAccountId) =>
		[
			loggedInUserAccountId !== null
				? {
						title: StarTitle,
						Cell: FavouriteCell,
						width: 3,
					}
				: undefined,
			{
				title: messages.name,
				Cell: TitleCell,
				sortField: NAME,
				width: loggedInUserAccountId ? 49 : 55,
			},
			{
				title: messages.administrators,
				Cell: AdministratorsCell,
				width: 20,
			},
			{
				title: messages.location,
				Cell: LocationCell,
				width: 25,
			},
			loggedInUserAccountId !== null
				? {
						Cell: TypeActions,
						width: 3,
						dataTestId: 'boards-main.content.headerCell.actions.container',
					}
				: undefined,
		].filter(Boolean),
);

export const getTableLabel = () => messages.tableAriaLabel;

export const getResultsAnnouncerMessage = () => messages.filterResultsAnnouncement;

export const INIT_DIALOG = 'single-board-frame/INIT_DIALOG';
export const SET_CONTEXT = 'single-board-frame/SET_CONTEXT';
export const SELECT_LOCATION = 'single-board-frame/SELECT_LOCATION';
export const UNSELECT_LOCATION = 'single-board-frame/UNSELECT_LOCATION';
export const FETCH_DATA_REQUEST = 'single-board-frame/FETCH_DATA_REQUEST';
export const FETCH_LOCATIONS_DATA_SUCCESS = 'single-board-frame/FETCH_LOCATIONS_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'single-board-frame/FETCH_DATA_FAILURE';
export const SET_NEW_LOCATION = 'single-board-frame/SET_NEW_LOCATION';
export const SET_LOCATION_SUCCESS = 'single-board-frame/SET_LOCATION_SUCCESS';
export const SET_LOCATION_FAILURE = 'single-board-frame/SET_LOCATION_FAILURE';
export const CANCEL_DIALOG = 'single-board-frame/CANCEL_DIALOG';

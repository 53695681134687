import React, { Component, type ComponentType } from 'react';
import memoizeOne from 'memoize-one';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { connect } from '@atlassian/jira-react-redux';
import isShallowEqualWithSafeStringSupport from '../utils/is-shallow-equal-with-safe-string-support';
import mapPayload from '../utils/map-data-payload-to-context-payload';
import type { AnalyticsDataPayload } from '../utils/types';

type WithAnalyticsContextProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContext: Record<any, any>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ConnectOptions<TContext = any> = {
	storeKey?: string;
	context?: TContext;
};

const ReduxAnalyticsData =
	<Props,>(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mapStateToAnalytics: (state: any, ownProps: Props) => AnalyticsDataPayload,
		connectOptions: ConnectOptions = {},
	) =>
	(WrappedComponent: ComponentType<Props>) => {
		// eslint-disable-next-line jira/react/no-class-components
		class ComponentWrappedInAnalytics extends Component<Props & WithAnalyticsContextProps> {
			static displayName = `ReduxAnalyticsData(${
				WrappedComponent.displayName || WrappedComponent.name
			})`;

			static defaultProps = {
				analyticsContext: {},
			};

			render() {
				const { analyticsContext, ...componentProps } = this.props;
				return (
					<AnalyticsContext data={analyticsContext}>
						{}
						{/* @ts-expect-error - TS2322 - Type 'Omit<Readonly<Props & WithAnalyticsContextProps> & Readonly<{ children?: ReactNode; }>, "analyticsContext">' is not assignable to type 'IntrinsicAttributes & Props & { children?: ReactNode; }'. */}
						<WrappedComponent {...componentProps} />
					</AnalyticsContext>
				);
			}
		}

		const mapStateToPropsFactory = (): ((
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			state: any,
			ownProps: Props,
		) => WithAnalyticsContextProps) => {
			const analyticsContextSelectorInstance = memoizeOne(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(state: any, ownProps: Props) => mapPayload(mapStateToAnalytics(state, ownProps)),
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (state: any, ownProps: Props) => ({
				analyticsContext: analyticsContextSelectorInstance(state, ownProps),
			});
		};

		return connect(
			mapStateToPropsFactory,
			() => ({}),
			null,
			{
				// to prevent connected component from unnecessary re-render
				areStatePropsEqual: (nextProps, prevProps) => {
					const { attributes: prevAttributes, ...prevFields } = prevProps.analyticsContext;
					const { attributes: nextAttributes, ...nextFields } = nextProps.analyticsContext;
					return (
						isShallowEqualWithSafeStringSupport(prevAttributes, nextAttributes) &&
						isShallowEqualWithSafeStringSupport(prevFields, nextFields)
					);
				},

				...connectOptions,
			},
			// @ts-expect-error - TS2345 - Argument of type 'typeof ComponentWrappedInAnalytics' is not assignable to parameter of type 'Component<Props & WithAnalyticsContextProps>'.
		)(ComponentWrappedInAnalytics);
	};

export default ReduxAnalyticsData;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'directories.boards-v2.main.content.empty.header',
		defaultMessage: "You don't have any boards, yet",
		description:
			'Heading for the empty state (empty screen) which is displayed when the Jira instance does not have any board.',
	},
	description: {
		id: 'directories.boards-v2.main.content.empty.description',
		defaultMessage: 'Create a board to start tracking your work.',
		description:
			'Message for the empty state (empty screen) to create a board which will make the empty state / screen effectively disappears.',
	},
});

import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../model';
import { getLocation } from '../../../../state/selectors/boards';
import type { State } from '../../../../state/types';
import Location from './view';

const mapStateToProps = () => (state: State, ownProps: EntityCellProps) => ({
	location: getLocation(state, ownProps.id),
});

export default connect(mapStateToProps, {})(Location);

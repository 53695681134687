import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools';
import rootEpic from './epics';
import rootReducer from './reducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (initialState: any) =>
	createStore(
		// @ts-expect-error - TS2345 - Argument of type '(state: { context: undefined; dialog: undefined; newLocation: undefined; } | undefined, action: any) => unknown' is not assignable to parameter of type 'Reducer<{ context: undefined; dialog: undefined; newLocation: undefined; } | undefined>'.
		rootReducer,
		initialState,
		composeWithDevtools({ name: 'Move Single Board' })(
			applyMiddleware(createEpicMiddleware(rootEpic)),
		),
	);

import React, { type ComponentType } from 'react';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import type { DirectoryProps } from '../model';
import messages from './messages';

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	CreateButton: ComponentType<Record<any, any>>;
	BoardsDirectory: ComponentType<DirectoryProps>;
} & DirectoryProps & {
		intl: IntlShape;
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		CreateButton,
		BoardsDirectory,
		intl: { formatMessage },
		...boardsDirectoryProps
	} = props;

	const actions = <CreateButton />;
	return (
		<>
			<DocumentTitle title={formatMessage(messages.pageTitle)} />
			<PageHeader truncateTitle actions={actions}>
				{formatMessage(messages.pageTitle)}
			</PageHeader>
			<BoardsDirectory {...boardsDirectoryProps} />
		</>
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteBoard: {
		id: 'directories.boards-v2.delete-modal.delete-board',
		defaultMessage: 'Delete {boardName}',
		description:
			'Delete board modal heading for regular board, i.e. not private and therefore we have access to its name.',
	},
	deletePrivateBoard: {
		id: 'directories.boards-v2.delete-modal.delete-private-board',
		defaultMessage: 'Delete board',
		description:
			'Delete board modal heading for private board, i.e. we have no access to its name.',
	},
	cancel: {
		id: 'directories.boards-v2.delete-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the cancel button that is located at the footer of the delete modal.',
	},
	deleteCaption: {
		id: 'directories.boards-v2.delete-modal.delete-caption',
		defaultMessage: 'Delete',
		description:
			'Label for the delete button that is located at the footer of the delete modal. Clicking this button will delete the board.',
	},
});

import { connect } from '@atlassian/jira-react-redux';
import { storeOptions } from '../../../../common/remapped-redux';
import type { State } from '../../../../state';
import { getEmptyFilterResultConfiguration } from '../../../../state/selectors';
import DefaultEmptyView from './view';

const mapStateToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => {
	const configuration = getEmptyFilterResultConfiguration(state);
	// @ts-expect-error - TS2339 - Property 'message' does not exist on type 'EmptyFilterResultConfiguration'.
	if (!configuration.message) {
		throw new Error('Invalid state - Default empty view should not be mounted');
	}
	return {
		// @ts-expect-error - TS2339 - Property 'message' does not exist on type 'EmptyFilterResultConfiguration'.
		message: configuration.message,
	};
};

export default connect(mapStateToProps, null, null, storeOptions)(DefaultEmptyView);

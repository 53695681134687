import React, { type ReactNode, Component } from 'react';
import RenderedContext from '../context';

type ReporterProps = {
	children: ReactNode;
	onFirstMount: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class TreeReporter extends Component<ReporterProps> {
	onSubtreeMount = () => {
		if (!this.hasAnyMounted) {
			this.hasAnyMounted = true;
			this.props.onFirstMount();
		}
	};

	hasAnyMounted = false;

	render() {
		const { children } = this.props;

		return (
			<RenderedContext.Provider value={{ reportMount: this.onSubtreeMount }}>
				{children}
			</RenderedContext.Provider>
		);
	}
}

import type { Props } from '../model';

// SET APP PROPS

export const SET_APP_PROPS = 'state.SET_APP_PROPS' as const;

export type SetAppPropsAction = {
	type: typeof SET_APP_PROPS;
	payload: Props;
};

export const setAppProps = (payload: Props): SetAppPropsAction => ({
	type: SET_APP_PROPS,
	payload,
});

// COPY BOARD

export const COPY_BOARD = 'state.COPY_BOARD' as const;

export type CopyBoardAction = {
	type: typeof COPY_BOARD;
};

export const copyBoard = (): CopyBoardAction => ({
	type: COPY_BOARD,
});

// COPY BOARD FAILED

export const COPY_BOARD_FAILED = 'state.COPY_BOARD_FAILED' as const;

type CopyBoardFailedPayload = {
	reason: 'board-not-found' | 'unknown';
};

export type CopyBoardFailedAction = {
	type: typeof COPY_BOARD_FAILED;
	payload: CopyBoardFailedPayload;
};

export const copyBoardFailed = (payload: CopyBoardFailedPayload): CopyBoardFailedAction => ({
	type: COPY_BOARD_FAILED,
	payload,
});

// BOARD COPIED

export const BOARD_COPIED = 'state.BOARD_COPIED' as const;

export type BoardCopiedAction = {
	type: typeof BOARD_COPIED;
};

export const boardCopied = (): BoardCopiedAction => ({
	type: BOARD_COPIED,
});

export type Action =
	| SetAppPropsAction
	| CopyBoardAction
	| BoardCopiedAction
	| CopyBoardFailedAction;
